import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { KycService } from "../../services/kyc.service";
import { SelfieService } from "../../services/selfie.service";
import { Check } from "../../models/check";
import { EndUserDocInfo } from "../../models/end-user-doc-info";
import { forkJoin } from "rxjs";
import { FeasModalComponent } from "../../shared/feas-modal/feas-modal.component";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { UiStateService } from "../../services/ui-state.service";
import { Ng2DeviceService } from "ng2-device-detector";
import { DataStorageService } from "src/app/services/data-storage.service";
@Component({
  selector: "app-selfie-check",
  templateUrl: "./selfie-check.component.html",
  styleUrls: ["./selfie-check.component.scss"],
})
export class SelfieCheckComponent implements OnInit {
  selfieObj: Check;
  livenessObj: Check;
  isUpdate: boolean = false;
  endUserDocInfo: EndUserDocInfo;

  currentStepNo: any;
  bsModalRef: BsModalRef;

  //Mobile Vars
  isMobile: boolean;
  step: number = 1;

  //kyc vars
  isSelfieDone: boolean;
  deviceInfo: any;
  geolocation: string;
  deviceName: string;

  constructor(
    public _app: AppService,
    public _data: DataService,
    public _kyc: KycService,
    public _selfie: SelfieService,
    private modalService: BsModalService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public uiStateService: UiStateService,
    private deviceService: Ng2DeviceService,
    private storage: DataStorageService
  ) {this.deviceInformation();}

  ngOnInit() {
    this.isMobile = this.uiStateService.getIsMobileResolution();
    this.currentStepNo = this._kyc.getStepNo("selfie-check");
    let checks = <Check[]>this._kyc.getData("checks");
    console.log(checks);
    this.selfieObj = checks.filter(
      obj => obj.standardCheck.slug == "selfie-check"
    )[0];
    this.livenessObj = checks.filter(
      obj => obj.standardCheck.slug == "liveness-check"
    )[0];
    // console.log(this.selfieObj);
    // console.log(this.livenessObj);
    console.log(this.currentStepNo);
    this.getFaceDetails();
    console.log("ISMOBILE: ", this.isMobile);
  }

  ngOnDestroy() {
    console.log("destroyed selfie");
    if (this._selfie.videoStream) {
      this._selfie.stopVideo();
      this._selfie.stopAudio();
    }
    if (this.bsModalRef) {
      this.bsModalRef.content.onClose.subscribe(result => {
        console.log(result);
        // var video = <HTMLMediaElement>document.getElementById('videoo');
        if (result && Object.keys(result).length) {
          console.log(result);
        }
      });
      this.bsModalRef.hide();
    }
  }

  getFaceDetails() {
    var livenessDetails$ = this._app.getLivenessDetails(
      this.livenessObj["endUserCheckID"]
    );
    var selfieDetails$ = this._app.getSelfieDetails(
      this.selfieObj["endUserCheckID"]
    );
    forkJoin([livenessDetails$, selfieDetails$]).subscribe(
      response => {
        console.log(response);
        if (response && response[0] && response[0].size) {
          this.isUpdate = true;
          const blob = new Blob([response[0]], { type: "video/mp4" });
          var file = new File([blob], "hero.mp4", { type: "video/mp4" });
          setTimeout(() => {
            this.invokeSaveAsDialog(blob);
          }, 0);
          this.isSelfieDone = true;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  invokeSaveAsDialog(blob) {
    // this.videoBlob = blob ;
    console.log(blob);
    // this.shouldShowFinalVideo = true ;
    // var videoSection = document.querySelector("#userVideoSection");
    // (<HTMLElement>videoSection).style.display = 'block' ;
    var video = document.querySelector("video#userVideo");
    if (video) {
      video["src"] = window.URL.createObjectURL(blob);
    }
  }

  openCameraModal() {
    // console.log({ selfie: this.selfieObj, liveness: this.livenessObj });
    const initialState = {
      title: `Record Video`,
      type: "record-video",
      selfieConfigData: { selfie: this.selfieObj, liveness: this.livenessObj },
      
    };
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      console.log(result);

      //   this.finishKyc();
      if (result && Object.keys(result).length) {
        console.log(result);
        this.isSelfieDone = true;
        this._data.toastrShow(result.message, "info");
        this.finishKyc();
        // this.gotoStep("isFinalStep");
      }
    });
  }

  shouldShowButton(type) {
    let kycState = <Check[]>this._kyc.getData("kycState");
    let idStateObj = kycState.filter(
      obj => obj["standardSlugGroup"] == "selfie-check"
    )[0];
    // console.log(kycState);
    // console.log(idStateObj);
    return idStateObj[type];
  }

  async logActivity() {
    let name = await this.storage.individualUserInfo;//this._data.getCookieObjectByName("individualUserInfo");

    let logData = {
      entity: "a",
      value: "b",
      message: `Uploaded Selfie & Video`,
      page: `Selfie Check`,
      os:this.deviceInfo.os,
      browser:this.deviceInfo.browser,
      device:this.deviceName,
      source:"FEAS",
      geoLocation:this.geolocation
    };

    this._app.saveUserActivityLogNew(logData, name["userName"]).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err);
      }
    );
  }
  deviceInformation() {    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      if(this.deviceService.device=="unknown"){
        if(isDesktopDevice){
          this.deviceName='Desktop';
        }
        if(isMobile){
          this.deviceName='Mobile';
        }
        if(isTablet){
          this.deviceName='Tablet';
        }
      }else this.deviceName=this.deviceInfo.device;
    console.log(this.deviceInfo);
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          this._app.getLocation(position.coords.latitude,position.coords.longitude).subscribe(res=>{
            if(res.city==""){
              this.geolocation=`${res.locality}, ${res.countryName}`;
            }else this.geolocation=`${res.city}, ${res.countryName}`;          
          });
          // let geocoder = new google.maps.Geocoder();
        });
    } else {
       this.geolocation=null;
    }
}
  finishKyc() {
    console.log("Over");
    this.router.navigate(["/kyc/preview"]);
    this.logActivity();
  }

  gotoStep(stepNo) {
    let state = this._kyc.getCurrentState(stepNo);
    let navRoute = this._kyc.getData("routesMap")[state["standardSlugGroup"]];
    console.log(state);
    this.router.navigate([navRoute]);
  }
  stepForward(currentStep) {
    // this.goingForward = true;
    this.step = currentStep + 1;
    console.log(this.step);
  }
  stepBack(currentStep) {
    // this.goingForward = false;
    this.step = currentStep - 1;
    console.log(this.step);
  }
}
