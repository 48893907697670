import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";

//Pages
import { AppComponent } from "./app.component";
import { TestComponent } from "./pages/test/test.component";
import { Test2Component } from "./pages/test2/test2.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { AccessDeniedComponent } from "./pages/access-denied/access-denied.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { GettingStartedComponent } from "./pages/getting-started/getting-started.component";
import { KycComponent } from "./pages/kyc/kyc.component";
import { AadharFeasComponent } from './pages/aadhar-feas/aadhar-feas.component';
import { AadharNumberComponent } from './pages/aadhar-feas/aadhar-number/aadhar-number.component';
import { OtpCheckComponent } from './pages/aadhar-feas/otp-check/otp-check.component';
import { FetchDetailsComponent } from './pages/aadhar-feas/fetch-details/fetch-details.component';
import { VerificationResultComponent } from './pages/aadhar-feas/verification-result/verification-result.component';
import { KycIndiaTncComponent } from './pages/kyc-india-tnc/kyc-india-tnc.component';

//Interceptor
import { ICOInterceptor, ErrorInterceptor } from "./interceptors";

//Modules
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { CookieModule } from "ngx-cookie";
import { WebcamModule } from "ngx-webcam";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CustomFormsModule } from "ng2-validation";
import { ImageCropperModule } from "ngx-image-cropper";
import { ToastrModule } from "ngx-toastr";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgOtpInputModule } from  'ng-otp-input';

//Services
import { UiStateService } from "./services/ui-state.service";
import { DataService } from "./services/data.service";
import { AppService } from "./services/app.service";
import { KycService } from "./services/kyc.service";
import { SelfieService } from "./services/selfie.service";
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
//Guards
import { AuthGuard } from "./guards/auth.guard";
import { DeactivateGuard } from "./guards/deactivate.guard";
import { KycGuard } from "./guards/kyc.guard";

//Pipes
import { ObjArrPipe } from "./pipes/obj-arr.pipe";
import { NoSanitizePipe } from "./pipes/no-sanitize.pipe";

//Components
import { KycSvgComponent } from "./components/kyc-svg/kyc-svg.component";
import { IdCheckComponent } from "./components/id-check/id-check.component";
import { AddressCheckComponent } from "./components/address-check/address-check.component";
import { SelfieCheckComponent } from "./components/selfie-check/selfie-check.component";
import { PreviewComponent } from "./components/preview/preview.component";


// Shared
import { FeasLoaderComponent } from "./shared/feas-loader/feas-loader.component";
import { FeasModalComponent } from "./shared/feas-modal/feas-modal.component";

//directives
import { DndDirective } from "./directives/dnd.directive";
import { ShowImageDirective } from "./directives/show-image.directive";
import { DigilockCheckComponent } from './pages/digilock-check/digilock-check.component';
import { CustomFlowComponent } from './pages/custom-flow/custom-flow.component';
import { CustomFlowTncComponent } from './pages/custom-flow-tnc/custom-flow-tnc.component';
import { IdCheckNewComponent } from './components/id-check-new/id-check-new.component';
import { PassiveLivinessNewComponent } from './components/passive-liviness-new/passive-liviness-new.component';
import { BankVerificationComponent } from './components/bank-verification/bank-verification.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SubmitAfterComponent } from './components/submit-after/submit-after.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  // return new TranslateHttpLoader(http, AppConfig.API_URL+'/static/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    Test2Component,
    ObjArrPipe,
    NoSanitizePipe,
    AccessDeniedComponent,
    WelcomeComponent,
    KycSvgComponent,
    TermsComponent,
    GettingStartedComponent,
    IdCheckComponent,
    AddressCheckComponent,
    SelfieCheckComponent,
    KycComponent,
    FeasLoaderComponent,
    FeasModalComponent,
    DndDirective,
    ShowImageDirective,
    PreviewComponent,
    AadharFeasComponent,
    AadharNumberComponent,
    OtpCheckComponent,
    FetchDetailsComponent,
    VerificationResultComponent,
    KycIndiaTncComponent,
    DigilockCheckComponent,
    CustomFlowComponent,
    CustomFlowTncComponent,
    IdCheckNewComponent,
    PassiveLivinessNewComponent,
    BankVerificationComponent,
    SubmitAfterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true
    }),
    CookieModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    Ng2DeviceDetectorModule.forRoot(),
    WebcamModule,
    BrowserAnimationsModule,
    CustomFormsModule,
    ImageCropperModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    NgOtpInputModule,
    NgSelectModule,
  ],
  providers: [
    AuthGuard,
    DeactivateGuard,
    KycGuard,
    UiStateService,
    TranslateService,
    DataService,
    KycService,
    SelfieService,
    AppService,
    // { provide: "API_URL", useValue: "https://api.dev.kychub.com" },
    // { provide: "API_URL", useValue: "https://api.kychub.com" },
    // { provide: "API_URL", useValue: "https://api.stage.kychub.com" },

    { provide: "API_URL", useValue: "https://api.sandbox.kychub.com" }, //sandbox prod
    {
      provide: "User_Placeholder",
      useValue: "https://www.hosted.kychub.com/dashboard/assets/img/avatar/avatar.png"
    },
    { provide: HTTP_INTERCEPTORS, useClass: ICOInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  entryComponents: [TestComponent, Test2Component, FeasModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
