import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { KycService } from "../../services/kyc.service";
import { Check } from "../../models/check";
import { IDDocument } from "../../models/document";
import { EndUserDocInfo } from "../../models/end-user-doc-info";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";

import { FeasModalComponent } from "../../shared/feas-modal/feas-modal.component";
import { UiStateService } from "../../services/ui-state.service";
import { Ng2DeviceService } from "ng2-device-detector";
import { DataStorageService } from "src/app/services/data-storage.service";
@Component({
  selector: "app-id-check",
  templateUrl: "./id-check.component.html",
  styleUrls: ["./id-check.component.scss"],
})
export class IdCheckComponent implements OnInit {
  checkObj: Check;
  isUpdate: boolean = false;
  endUserDocInfo: EndUserDocInfo;

  currentStepNo: any;
  selectedCountry: any;
  countries: any[] = [];
  states: any[] = [];
  validDocument: any = null;

  idDoc: IDDocument = new IDDocument();

  startDate: any;
  endDate: any;

  showSteps: any = {
    showStep1: false,
    showStep2: false,
    showStep3: false,
  };

  selectedId: any = {};
  selectedIdMessage: any = {};

  bsModalRef: BsModalRef;
  extAllowed: any[] = ["image/png", "image/jpeg", "image/jpg", "pdf"];

  //captured doc
  captured_doc: any;

  //Mobile Vars
  isMobile: boolean;
  shouldShowInstructions: boolean = false;
  step: number = 1;

  //   pdf upload var
  uploadedFile1: any;
  uploadedFile2: any;
  uploadedDocType: any;
  deviceInfo: any;
  geolocation: string;
  deviceName: string;
  ip: any;
  allowDifferentCountryIdentityDoc: any;
  showCountry: boolean;
  idSlug: any;
  isOtherDocNeed: boolean;
  manDocCountry: string;
  manDocSlug: String;

  constructor(
    public _app: AppService,
    public _data: DataService,
    public _kyc: KycService,
    private modalService: BsModalService,
    public uiStateService: UiStateService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private deviceService: Ng2DeviceService,
    private storage: DataStorageService
  ) {
    // console.log("Ever REached Here?");
    this.deviceInformation();
  }

  ngOnInit() {
    this.idSlug = this.activatedRoute.snapshot.data.idSlug;

    console.log("data id:", this.idSlug);
    this.idDoc["docIssueDate"] = "";
    this.idDoc["docExpiryDate"] = "";
    this.currentStepNo = this._kyc.getStepNo(this.idSlug);
    let checks = <Check[]>this._kyc.getData("checks");
    this.selectedCountry = this._kyc.getData("kycOriginCountry");
    // console.log(checks);
    this.checkObj = checks.filter(
      (obj) => obj.standardCheck.slug == this.idSlug
    )[0];
    // console.log(this.checkObj);
    // console.log(this.currentStepNo);
    this.getUserDocsDetails();
    this.getAllCountries();
    this.getIp();
    this.isMobile = this.uiStateService.getIsMobileResolution();
    // console.log("isMobile: ", this.isMobile);

    // setInterval(() => {
    //   this._data.toastrShow("res.message", "info");
    // }, 10000);
    this.getAllDetails();
    this.showCountry =
      /*this._data.getCookiesByName("allowCountryChange")*/ this.storage
        .allowCountryChange == false
        ? false
        : true;
    
    // this.isOtherDocNeed= this.storage.othersRequired

    this.manDocCountry = this.storage.mandatoryDocCountry; //this._data.getCookiesByName("mandatoryDocCountry");
    this.manDocSlug = this.storage.mandatoryFirstDocSlug; //this._data.getCookiesByName("mandatoryFirstDocSlug");

    if (!this.showCountry) {
      this.getAllowedDocs(this.selectedCountry);
    }
  }

  getAllCountries() {
    this._app.getAllCountries().subscribe((response) => {
      this.countries = response.data;
    });
  }

  getDocFromServer(checkGuid) {
    this._app.getDocFromServer(checkGuid).subscribe((res) => {
      this.idDoc[
        "docFrontUrl"
      ] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
      this.idDoc[
        "docRearUrl"
      ] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
    });
  }

  getAllowedDocs(country) {
    // console.log(country);
    if (country && this.countries && this.countries.length) {
      let countryCode = this.countries.filter(
        (obj) => obj.countryName == country
      );
      let countryObj = {
        countryCode: countryCode[0].countryCode,
      };
      this._app.getProvinceList(countryObj).subscribe((res) => {
        // console.log(res);
        this.states = res.data;
      });
    }
    this._app.getAllowedDocs(country).subscribe((res) => {
      let allowedDocs = res.data;
      this.validDocument = [];
      // if (this.standardCheckGroup == "id-checks") {
      if (
        this.idSlug == "id-doc-check" &&
        this.manDocCountry &&
        this.selectedCountry.toUpperCase() ==
          this.manDocCountry.toUpperCase() &&
        this.manDocSlug
      ) {
        allowedDocs["validIdentityDocs"].forEach((element) => {
          if (element.docSlug == this.manDocSlug) {
            this.validDocument.push(element);
          }
        });
      } else if (
        this.idSlug == "id-doc-check-2" &&
        this.manDocCountry &&
        this.selectedCountry.toUpperCase() ==
          this.manDocCountry.toUpperCase() &&
        this.manDocSlug
      ) {
        allowedDocs["validIdentityDocs"].forEach((element) => {
          if (element.docSlug != this.manDocSlug && element.docSlug!="other") {
            this.validDocument.push(element);
          }
        });
      } else {
        this.validDocument = allowedDocs["validIdentityDocs"];
      }
      console.log("valid Docs:", this.validDocument);
      // } else if (this.standardCheckGroup == "address-checks") {
      // this.validDocument = allowedDocs["validAddressDocs"];
      // }
      // console.log(allowedDocs);
    });
  }
  getAllDetails() {
    this._app.getClientConfig().subscribe(
      (response) => {
        this.allowDifferentCountryIdentityDoc =
          response.data.allowDifferentCountryIdentityDoc;
        console.log(this.allowDifferentCountryIdentityDoc);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getUserDocsDetails() {
    // this._data.changeLoaderVisibility(true);
    this._app
      .getUserDocsDetails(this.checkObj["endUserCheckID"])
      .subscribe((response) => {
        // this._data.changeLoaderVisibility(false)

        this.isUpdate = response.data.endUserDocInfo? true:false;
        this.endUserDocInfo = response.data.endUserDocInfo;
        this.idDoc = new IDDocument(response.data.endUserDocInfo);
        // this.idDoc.docExpiryDate = response.data.endUserDocInfo["docExpiryDate"]
        //   ? this._data.getNormalizedDate(response.data.endUserDocInfo["docExpiryDate"])
        //   : null;
        // this.idDoc.docIssueDate = response.data.endUserDocInfo["docIssueDate"]
        //   ? this._data.getNormalizedDate(response.data.endUserDocInfo["docIssueDate"])
        //   : null;
        // this.idDoc["docExpiryDate"]  = response.data.endUserDocInfo["docExpiryDate"];
        // this.idDoc["docIssueDate"]   = response.data.endUserDocInfo["docIssueDate"];

        this.idDoc.docExpiryDate = response.data.endUserDocInfo["docExpiryDate"]
          ? new Date(response.data.endUserDocInfo["docExpiryDate"])
              .toISOString()
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/")
          : null;
        this.endDate = this.idDoc.docExpiryDate;

        this.idDoc.docIssueDate = response.data.endUserDocInfo["docIssueDate"]
          ? new Date(response.data.endUserDocInfo["docIssueDate"])
              .toISOString()
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/")
          : null;
        this.startDate = this.idDoc.docIssueDate;
        // this.idDoc.docIssueDate=new Date(this.idDoc.docIssueDate)
        this.idDoc.docSlug =
          response.data.endUserDocInfo.documentInfo["docSlug"];
        this.idDoc.docName =
          response.data.endUserDocInfo.documentInfo["docName"];
        this.idDoc.issueYear = response.data.endUserDocInfo["issueYear"];
        this.idDoc.expiryYear = response.data.endUserDocInfo["expiryYear"];
        this.selectedId = response.data.endUserDocInfo.documentInfo["docName"];
        this.idDoc.docType = response.data.endUserDocInfo["docType"];
        if (
          response.data.endUserDocInfo.province &&
          response.data.endUserDocInfo.province["stateCode"]
        ) {
          this.idDoc["provinceCode"] =
            response.data.endUserDocInfo.province["stateCode"];
        }
        this.getAllowedDocs(this.selectedCountry);
        this._kyc.setTraverseState(this.currentStepNo + 1, true);

        this.getDocFromServer(this.checkObj["endUserCheckID"]);
      });
  }

  selectIdType(doc) {
    // this.idDoc = {} ;

    this.selectedId = doc.docName;
    this.idDoc.docSlug = doc.docSlug;

    let allowedID = this.validDocument;
    let isAttrAllowed = allowedID.filter((obj) => obj.docSlug == doc.docSlug);
    this.selectedIdMessage.rearPhotoMessage = isAttrAllowed[0].rearPhotoMessage;
    this.selectedIdMessage.frontPhotoMessage =
      isAttrAllowed[0].frontPhotoMessage;
    this.idDoc.docName = doc.docName;
  }

  fileChangeListener($event, docSlug, docUrlType) {
    var image: any = new Image();
    var file: File = $event[0] ? $event[0] : $event.target.files[0];
    var name = file.name;

    if (this.uploadedFile1) {
      this.uploadedFile2 = file;
    } else {
      this.uploadedFile1 = file;
    }

    // this.uploadedDocType1 = docUrlType;
    if (file) {
      if (this.extAllowed.indexOf(file.type) >= 0) {
        var reader = new FileReader();
        var that = this;
        that.openCropModal($event, docSlug, docUrlType);
      } else if (file.type === "application/pdf") {
        var fileReader = new FileReader();
        var base64;
        var that = this;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
          base64 = fileReader.result;
          // Print data in console

          that.idDoc[docUrlType] = base64;
        };
        // Convert data to base64
        fileReader.readAsDataURL(file);
        // this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
      } else {
        let alertObj = {
          message:
            "File extension not supported , we support png , jpeg & jpg.",
          status: "danger",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        // this._data.displayUniversalAlert(alertObj);
        // console.log(alertObj);
      }
    }
  }

  onFilesChange($event, docSlug, docUrlType) {
    this.fileChangeListener($event, docSlug, docUrlType);
  }

  openCropModal(event, docSlug, docUrlType) {
    const initialState = {
      title: `Crop Image`,
      type: "crop-image",
      loadedImageEvent: event,
    };
    // console.log(event);
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    // console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe((result) => {
      // console.log("results", result);
      if (result && Object.keys(result).length) {
        // console.log(result);

        this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
        // console.log(this.idDoc);
      }
    });
  }

  openCropModalCamera(type, data, docSlug, docUrlType) {
    const initialState = {
      title: `Crop Image`,
      type: "crop-image-b64",
      imgAsb64: data,
    };

    // console.log(event);
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    // console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe((result) => {
      // console.log("results", result);
      if (result && Object.keys(result).length) {
        if (type == "reardoc") {
          // console.log("From reardoc");
          this.idDoc["docRearUrl"] = result["image"];
          this.idDoc[docUrlType] = result["image"];
          this.idDoc["docSlug"] = docSlug;
        } else {
          console.log("From frontDoc");
          this.idDoc["docFrontUrl"] = result["image"];
          this.idDoc[docUrlType] = result["image"];
          this.idDoc["docSlug"] = docSlug;
        }

        // console.log(this.idDoc);
      }
    });
  }

  checkAndSave() {
    let countryCode = this.countries.filter(
      (obj) => obj.countryName == this.selectedCountry
    );
    this.idDoc["countryCode"] = countryCode[0].countryCode;
    this.saveIdDocs();
    this.logActivity();
  }

  async logActivity() {
    let name = await this.storage.individualUserInfo;//this._data.getCookieObjectByName("individualUserInfo");

    let logData = {
      entity: "a",
      value: "b",
      message: `Updated ID Docs`,
      page: `ID Doc Check`,
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      device: this.deviceName,
      source: "FEAS",
      geoLocation: this.geolocation,
    };

    this._app.saveUserActivityLogNew(logData, name["userName"]).subscribe(
      (res) => {
        // console.log(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  }
  deviceInformation() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (this.deviceService.device == "unknown") {
      if (isDesktopDevice) {
        this.deviceName = "Desktop";
      }
      if (isMobile) {
        this.deviceName = "Mobile";
      }
      if (isTablet) {
        this.deviceName = "Tablet";
      }
    } else this.deviceName = this.deviceInfo.device;
    console.log(this.deviceInfo);
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._app
          .getLocation(position.coords.latitude, position.coords.longitude)
          .subscribe((res) => {
            if (res.city == "") {
              this.geolocation = `${res.locality}, ${res.countryName}`;
            } else this.geolocation = `${res.city}, ${res.countryName}`;
          });
        // let geocoder = new google.maps.Geocoder();
      });
    } else {
      this.geolocation = null;
    }
  }
  getIp() {
    // this._app.getSystemIp().subscribe((res:any)=>{
    //   this.ip=res.ip;
    //   console.log("ip adress",this.ip)
    // });
  }
  saveIdDocs() {
    // var tzoffset = new Date().getTimezoneOffset() * 60000;
    // console.log(this.startDate);
    // console.log(this.endDate);
    // this.startDate= new Date(this.startDate-tzoffset);
    // this.endDate = new Date(this.endDate-tzoffset);

    let dateStart = this.startDate ? new Date(this.startDate) : null;
    let dateStartOffset = dateStart
      ? dateStart.getTimezoneOffset() * 60000
      : null;
    let normalizeStartDate = dateStart
      ? new Date(dateStart.getTime() - dateStartOffset)
          .toISOString()
          .split("T")[0]
      : null;

    let dateExpiry = this.endDate ? new Date(this.endDate) : null;
    let dateExpiryOffset = dateExpiry
      ? dateExpiry.getTimezoneOffset() * 60000
      : null;
    let normalizeExpiryDate = dateExpiry
      ? new Date(dateExpiry.getTime() - dateExpiryOffset)
          .toISOString()
          .split("T")[0]
      : null;

    // console.log(this.currentStepNo + 1);
    this._data.changeLoaderVisibility(true);
    this.idDoc["docType"] = "IDENTITY";
    // console.log("expiry date",this.idDoc)

    this.idDoc.docExpiryDate = normalizeExpiryDate;
    this.idDoc.docIssueDate = normalizeStartDate;

    // this.idDoc.docExpiryDate = new Date(this.endDate).toISOString().split("T")[0];
    //this.idDoc.docExpiryDate = new Date(this._data.getNormalizedDate(this.idDoc.docExpiryDate));
    //this.idDoc.docExpiryDate= new Date(this._data.getNormalizedDate(this.idDoc.docExpiryDate));//Sun Nov 01 2020 00:00:00 GMT-1000 (Hawaii-Aleutian Standard Time) -> 2020-11-01
    // this.idDoc.docIssueDate = new Date(this.startDate).toISOString().split("T")[0];
    //this.idDoc.docIssueDate = new Date(this._data.getNormalizedDate(this.idDoc.docIssueDate));
    //this.idDoc.docIssueDate= new Date(this._data.getNormalizedDate(this.idDoc.docIssueDate));
    // End user Actvity log data
    this.idDoc["os"] = this.deviceInfo.os;
    this.idDoc["browser"] = this.deviceInfo.browser;
    this.idDoc["device"] = this.deviceName;
    this.idDoc["location"] = this.geolocation;
    // this.idDoc["ip"] = this.ip;
    console.log(this.idDoc.docIssueDate);
    console.log(this.idDoc.docExpiryDate);
    this._app
      .saveUserDoc(this.idDoc, this.checkObj["endUserCheckID"])
      .subscribe((res) => {
        this.isUpdate = true;
        // console.log(res);
        let alertObj = {
          message: res["message"],
          status: "success",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        this._data.toastrShow(res.message, "info");
        // console.log(alertObj);
        this._data.changeLoaderVisibility(false);
        this.gotoStep(this.currentStepNo + 1);
      });
  }

  shouldShowInputField(attr) {
    let selectedDocSlug = this.idDoc["docSlug"];
    let allowedID = this.validDocument;
    if(allowedID){
      let isAttrAllowed = allowedID.filter(
        (obj) => obj.docSlug == selectedDocSlug
      );
      if (isAttrAllowed.length) {
        if (isAttrAllowed[0].mandatoryField.indexOf(attr) > 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  shouldShowButton(type) {
    let kycState = <Check[]>this._kyc.getData("kycState");
    let idStateObj = kycState.filter(
      (obj) => obj["standardSlugGroup"] == this.idSlug
    )[0];
    return idStateObj[type];
  }

  finishKyc() {
    // console.log("Over");
    // this.checkAndSave();
    console.log("Over");
    this.router.navigate(["/kyc/preview"]);
    this.logActivity();
  }

  openCameraModal(type, docSlug, docUrlType) {
    // console.log({ selfie: this.selfieObj, liveness: this.livenessObj });
    const initialState = {
      title: `Capture Document`,
      type: "capture-doc",
    };
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    // console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe((result) => {
      //get image from camera
      if (result && Object.keys(result).length) {
        // console.log(result);
        this.captured_doc = result;
        this.openCropModalCamera(type, this.captured_doc, docSlug, docUrlType);
      }
    });
  }

  gotoStep(stepNo) {
    let state = this._kyc.getCurrentState(stepNo);
    let navRoute = this._kyc.getData("routesMap")[state["standardSlugGroup"]];
    // console.log(state);
    this.router.navigate([navRoute]);
  }

  //Mobile view functions
  showInstructions() {
    this.shouldShowInstructions = this.shouldShowInstructions ? false : true;
  }

  stepForward(currentStep) {
    // this.goingForward = true;
    this.step = currentStep + 1;
    // console.log(this.step);
  }
  stepBack(currentStep) {
    // this.goingForward = false;
    this.step = currentStep - 1;
    // console.log(this.step);
  }

  nullify(docType) {
    this.idDoc[docType] = null;

    if (docType == "docRearUrl") {
      this.uploadedFile2 = null;
    } else {
      this.uploadedFile1 = null;
    }
    // this.uploadedFile = null;
  }
}
