import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KycIndiaService } from '../../../services/kyc-india.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-otp-check',
  templateUrl: './otp-check.component.html',
  styleUrls: ['./otp-check.component.scss']
})
export class OtpCheckComponent implements OnInit {
  @Input() state:any;
  @Output() step = new EventEmitter<any>();

  otpLength=6;
  previousData:any;
  isOtpVerified:boolean=false;
  otpConfig={
    length:this.otpLength,
    allowNumbersOnly:true
  }

  errorMsg='';
  form: FormGroup=this.formBuilder.group({
    otp:['',Validators.required]
  })
  userData:any;
  constructor(
    private formBuilder: FormBuilder,
    public _data: DataService,
    private kycService:KycIndiaService
  ) { }

  ngOnInit() {

    window.scrollTo(0,0);
    if(this.state!=undefined)
      this.previousData=this.state;
  }

  verifyOtp(){

    this.errorMsg='';
    this._data.changeLoaderVisibility(true);
    let payload={
      share_code: "2345",
      aadhaar_otp: this.form.value['otp'],
      reference_id: this.previousData.refId
  }
    this.kycService.verifyOtp(payload,this.previousData.referencePath).subscribe(result=>{
      this._data.changeLoaderVisibility(false);
        if(result.data['code']==200){
          this.userData = result.data['data'];
          // this.isOtpVerified=true;
          let state={
            state:{
              previous:this.previousData,
              data:this.userData
            },
            step:3
          }
          this.step.emit(state)
        }
        else{
          this.errorMsg=result.data['status']['statusMessage'];
        }
    });
      
  }

  resendOtp(){

    this._data.changeLoaderVisibility(true);
    let body={
      aadhaar_no:this.previousData['form']['aadharNo']
    }
    this.kycService.sendOtp(body,this.previousData.referencePath).subscribe(result=>{
      this._data.changeLoaderVisibility(false);
      if(result.data['code']==200){
        this.previousData.refId=result.data['reference_id'];
      }
    });
  }

  // goToFetchData(){
  //   let state={
  //     state:{
  //       previous:this.previousData,
  //       data:this.userData
  //     },
  //     step:3
  //   }
  //   this.step.emit(state)
  // }
  
  onOtpChange(event){
    this.errorMsg='';
    if(event.length==this.otpLength)
      this.form.get('otp').setValue(event);
    else
      this.form.get('otp').setValue('');
      
    console.log("otp event: ",event,event.length, this.form.value['otp']);
  }
}
