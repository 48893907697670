import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Params,
  NavigationEnd,
} from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { KycService } from "../../services/kyc.service";
import { IndividualUserInfo } from "../../models/individual-user-info";
import { Check } from "../../models/check";
import { State } from "../../models/state";
import { CustomFormsModule } from "ng2-validation";
import { first } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage.service";
@Component({
  selector: "app-kyc",
  templateUrl: "./kyc.component.html",
  styleUrls: ["./kyc.component.scss"],
})
export class KycComponent implements OnInit, OnDestroy {
  countries: any[] = [];
  selectedCountry: string;

  // routerSubscription : Array<Subscription> = [];
  routerSubscription: any;

  currentRoute: any;

  constructor(
    public _app: AppService,
    public _data: DataService,
    public _kyc: KycService,
    public router: Router,
    // public route      : ActivatedRouteSnapshot,
    public activatedRoute: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    private storage: DataStorageService
  ) {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route: any) => {
        console.log(route);
        this.currentRoute = route["url"];
        if (this.currentRoute == "/kyc" && this._kyc.isKycStateLoadingDone) {
          this.setRedirectionRoute();
        } else if (!this._kyc.isKycStateLoadingDone) {
          this._kyc.getLocalData();
          this._kyc.populateKycChecks();
          this._kyc.setKycState();
          this._kyc.isKycStateLoadingDone = true;
          this.setRedirectionRoute();
        }
      });
  }

  ngOnInit() {
    this.getCountries();
    // this._kyc.getLocalData();
    // this._kyc.populateKycChecks();
    // this._kyc.setKycState();

    // this.router.navigate(["/kyc/id-check"]);
  }

  setRedirectionRoute() {
    let redirectionRoute = this._kyc.getTraverseState(1);
    console.log(this._kyc.getCurrentState(1));
    console.log(redirectionRoute);
    this.router.navigate([redirectionRoute]);
  }

  ngOnDestroy() {
    // this.subs.forEach(s => s.unsubscribe());
    console.log("Destroyed");
    this.routerSubscription.unsubscribe();
  }

  getCountries() {
    if (/*localStorage.getItem('countries')*/ this.storage.countries) {
      this.countries = JSON.parse(
        this.storage.countries /*localStorage.getItem('countries')*/
      );
    } else {
      this._app.getAllCountries().subscribe((res) => {
        this.countries = res.data;
      });
    }
  }
}
