import { Component, OnInit , EventEmitter,  Output, Input} from '@angular/core';
import { FormGroup,FormBuilder } from '@angular/forms';
import { AppService } from "src/app/services/app.service";
@Component({
  selector: 'app-fetch-details',
  templateUrl: './fetch-details.component.html',
  styleUrls: ['./fetch-details.component.scss']
})
export class FetchDetailsComponent implements OnInit {
  @Input() state:any;
  @Output() step = new EventEmitter<any>();
  isData=false;
  fetchAadharDetails:FormGroup;
  previousData:any;
  photo_link="";
  constructor(
    private formBuilder: FormBuilder,
    private _app: AppService
  ) { }

  ngOnInit() {

    window.scrollTo(0,0);
    setTimeout(()=>{
      this.isData=true;
    },2000)
    this.fetchAadharDetails = this.formBuilder.group({
      fullName: [''],
      dob: [''] ,
      fathersName: [''],
      aadharNumber: [''],
      address: ['']
    })
    this.getaadharDetails();
  }
  identityCheck(){
    let state={
      step:4
    }
    this.step.emit(state)
  }

  getaadharDetails(){
    if(this.state!=undefined){
      this.previousData=this.state.data;
      this.photo_link=this.previousData['photo_link']?this.previousData['photo_link']:'';
      this.fetchAadharDetails.patchValue({
        fullName: this.previousData['name']?this.previousData['name']:'',
        dob: this.previousData['date_of_birth']?this.previousData['date_of_birth']:'',
        fathersName: this.previousData['father_name']?this.previousData['father_name']:'',
        aadharNumber: this.state.previous['form']['aadharNo']?this.state.previous['form']['aadharNo']:'',
        address: this.previousData['address']?this.previousData['address']:''
      })
      console.log("form values",this.fetchAadharDetails.value)
    }
  }
}
