import { Injectable } from "@angular/core";
import { any } from "@tensorflow/tfjs-core";

@Injectable({
  providedIn: "root",
})
export class DataStorageService {
  feas_token: any;
  auth_token: any;
  remoteChecks: any;
  document: any;
  digiReqId: any;
  feasType: any;
  themingInfo: any;
  countries: any;
  audios: any;
  logo: any;
  gettingStarted_image: any;
  allowCountryChange: any;
  mandatoryDocCountry: any;
  mandatoryFirstDocSlug: any;
  redirection: any;
  checkLinkGUID: any;
  counter: any;
  individualUserInfo: any;
  lang: any;
  othersRequired: any;
  resCountry: any;
  redirectUri: any;
  kycOriginCountry: any;
  isProfileUpdatable: any;
  isCountryUpdatable: any;
  idCount:any;
  clientsApiEndpoint:any;
  
  constructor() {}

  removeAllCookie() {
    this.feas_token = undefined;
    this.auth_token = undefined;
    this.remoteChecks = undefined;
    this.document = undefined;
    this.digiReqId = undefined;
    this.feasType = undefined;
    this.themingInfo = undefined;
    this.countries = undefined;
    this.audios = undefined;
    this.logo = undefined;
    this.counter = undefined;
    this.gettingStarted_image = undefined;
    this.allowCountryChange = undefined;
    this.mandatoryDocCountry = undefined;
    this.mandatoryFirstDocSlug = undefined;
    this.redirection = undefined;
    this.checkLinkGUID = undefined;
    this.individualUserInfo = undefined;
    this.lang = undefined;
    this.othersRequired = undefined;
    this.resCountry = undefined;
    this.redirectUri = undefined;
    this.kycOriginCountry = undefined;
    this.isProfileUpdatable = undefined;
    this.isCountryUpdatable = undefined;
    this.idCount=undefined;
    this.clientsApiEndpoint=undefined;
  }
}
