import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { DataService } from "../services/data.service";
import { DataStorageService } from "../services/data-storage.service";
@Injectable()
export class ICOInterceptor implements HttpInterceptor {
  constructor(
    private _data: DataService,
    private storage:DataStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: "application/json",
    };

    // let access_token = this._data.getCookiesByName("feas_token"); //multipart/form-data
    // let auth_token = this._data.getCookiesByName("auth_token"); //multipart/form-data
    let access_token = this.storage.feas_token;
    let auth_token = this.storage.auth_token;
    let cryptoApi = this.storage.clientsApiEndpoint;
    console.log("crypto includes: ",request.url.indexOf(cryptoApi)," ",cryptoApi);
    // console.log(":::::::::::::::::::::::", access_token);
    if(request.url.indexOf("faas/india-kyc/feas-config")> 0){
      headersConfig["X-Auth-Token"]=`${access_token}`
      headersConfig["appsource"] = `FEAS`;
    }
    else if (access_token && request.url.indexOf("video-selfie") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
      headersConfig["appsource"] = `FEAS`;
    }
    else if (access_token && request.url.indexOf("/doc/video/") > 0) {
      headersConfig["X-Auth-Token"] = `${access_token}`;
      headersConfig["Content-Type"] = "application/octet-stream";
      headersConfig["appsource"] = `FEAS`;
      console.log("Video Here");
      console.log(request);
    }
    else if(request.url.indexOf('/upi/verify')>0 || request.url.indexOf('bank/verify')>0){
      headersConfig["X-Auth-Token"] = `${access_token}`;
      headersConfig["appsource"] = `FEAS`;
    }
    else if(request.url.indexOf('/face/match')>0){
      headersConfig["X-Auth-Token"] = `${access_token}`;
      headersConfig["Content-Type"]='file';
      headersConfig["appsource"] = `FEAS`;
    }
    else if (access_token && request.url.indexOf("video-selfie") < 0) {
      // headersConfig["Authorization"] = `Bearer ${access_token}` ;
      headersConfig["X-Auth-Token"] = `${access_token}`;
      headersConfig["Content-Type"] = `application/json`;
      headersConfig["appsource"] = `FEAS`;
      console.log("I am running, ", access_token);
    } 
    else if (request.url.indexOf(cryptoApi)){
      console.log("crypto api: ",cryptoApi);
      
      headersConfig["Content-Type"] = `application/json`;
    }
    else if (auth_token) {
      headersConfig["X-Auth-Token"] = `${auth_token}`;
      headersConfig["Content-Type"] = `application/json`;
      headersConfig["appsource"] = `FEAS`;
    }
    // console.log(request);
    request = request.clone({
      setHeaders: headersConfig,
    });

    return next.handle(request);
  }
}
