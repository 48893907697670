import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { KycService } from "../../services/kyc.service";
import { DomSanitizer } from "@angular/platform-browser";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FeasModalComponent } from "../../shared/feas-modal/feas-modal.component";
import { Ng2DeviceService } from "ng2-device-detector";
import { DataStorageService } from "src/app/services/data-storage.service";

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent implements OnInit {
  hashId: any;

  addressDocProcessedInfo: any;
  amlProcessedInfo: any = {
    crimeCheck: null,
    pepCheck: null,
    sanctionCheck: null,
  };
  identityDocProcessedInfo: any;
  identityDocProcessedInfo2: any;
  livenessProcessedInfo: any;
  selfieProcessedInfo: any;

  userSelfie: any = null;

  //Modal
  bsModalRef: BsModalRef;
  deviceInfo: any;
  geolocation: string;
  deviceName: string;
  ip: any;
  isRedirect: boolean;
  cryptoApi:any;

  constructor(
    public _app: AppService,
    public _kyc: KycService,
    public sanitizer: DomSanitizer,
    public _data: DataService,
    public router: Router,
    private modalService: BsModalService,
    private deviceService: Ng2DeviceService,
    private storage: DataStorageService
  ) {

    this.cryptoApi=this.storage.clientsApiEndpoint;
  }

  ngOnInit() {
    this.hashId = this._kyc.getData("checkLinkGUID");
    this.isRedirect =
      /*this._data.getCookiesByName("redirection")*/ this.storage.redirection ==
      false
        ? false
        : true;
    console.log(this.hashId);
    this.getPreviewData();
    this.deviceInformation();
  }

  getPreviewData() {
    // this._data.changeLoaderVisibility(true);
    this._app.getPreviewData(this.hashId).subscribe(
      (res) => {
        console.log(res);
        // this._data.changeLoaderVisibility(false);
        this.mapResponseData(res.data);
      }
      // (err)=>{ this._data.changeLoaderVisibility(false) }
    );
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }

  mapResponseData(data) {
    this.addressDocProcessedInfo = data.addressDocProcessedInfo;
    this.amlProcessedInfo = data.amlProcessedInfo;
    this.identityDocProcessedInfo = data.identityDocProcessedInfo;
    this.identityDocProcessedInfo2 = data.identityDocProcessedInfo2;
    this.livenessProcessedInfo = data.livenessProcessedInfo;
    this.selfieProcessedInfo = data.selfieProcessedInfo;
    if (this.identityDocProcessedInfo) {
      this.getDocFromServer(
        this.identityDocProcessedInfo.endUserDocInfo.endUserCheck
          .endUserCheckID,
        true,
        true
      );
      this.identityDocProcessedInfo.faceUrl = `data:image/png;base64,${this.identityDocProcessedInfo.faceUrl}`;
    }
    if (this.identityDocProcessedInfo2) {
      this.getDocFromServer(
        this.identityDocProcessedInfo2.endUserDocInfo.endUserCheck
          .endUserCheckID,
        true,
        false
      );
      this.identityDocProcessedInfo2.faceUrl = `data:image/png;base64,${this.identityDocProcessedInfo2.faceUrl}`;
    }
    if (this.addressDocProcessedInfo) {
      this.getDocFromServer(
        this.addressDocProcessedInfo.endUserDocInfo.endUserCheck.endUserCheckID,
        false,
        false
      );
    }
    if (this.livenessProcessedInfo) {
      this.getLivenessDetails(
        this.livenessProcessedInfo.endUserCheck.endUserCheckID
      );
    }
    if (this.selfieProcessedInfo) {
      this.getSelfieDetails(
        this.selfieProcessedInfo.endUserCheck.endUserCheckID
      );
    }
  }

  getLivenessDetails(endUserCheckID) {
    this._app.getLivenessDetails(endUserCheckID).subscribe((res) => {
      if (res) {
        console.log(res);
        const blob = new Blob([res], { type: "video/mp4" });

        var file = new File([blob], "hero.mp4", { type: "video/mp4" });
        console.log(file);
        console.log(blob);
        var videoSection = document.querySelector("#previewVideoSection");
        (<HTMLElement>videoSection).style.display = "block";
        var video = document.querySelector("video#previewVideo");
        video["src"] = window.URL.createObjectURL(blob);
      }
    });
  }

  getSelfieDetails(endUserCheckID) {
    this._app.getSelfieDetails(endUserCheckID).subscribe((res) => {
      if (res.data) {
        // this.isUpdate = true ;
        this.userSelfie = res.data ? `data:image/png;base64,${res.data}` : null;
      }
      console.log(res);
    });
  }

  getDocFromServer(checkGuid, isIdDoc, primaryId) {
    this._app.getDocFromServer(checkGuid).subscribe((res) => {
      if (isIdDoc && primaryId) {
        this.identityDocProcessedInfo.endUserDocInfo[
          "docFrontUrl"
        ] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
        this.identityDocProcessedInfo.endUserDocInfo[
          "docRearUrl"
        ] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
      } else if (isIdDoc && !primaryId) {
        this.identityDocProcessedInfo2.endUserDocInfo[
          "docFrontUrl"
        ] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
        this.identityDocProcessedInfo2.endUserDocInfo[
          "docRearUrl"
        ] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
      } else {
        this.addressDocProcessedInfo.endUserDocInfo[
          "docFrontUrl"
        ] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
        this.addressDocProcessedInfo.endUserDocInfo[
          "docRearUrl"
        ] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
      }
      console.log(this.identityDocProcessedInfo);
      console.log(this.addressDocProcessedInfo);
    });
  }

  finalSubmit() {
    let hashId = this.storage.checkLinkGUID; //this._data.getCookieObjectByName("checkLinkGUID");
    // this.hashId["os"] = this.deviceInfo.os;
    // this.hashId["browser"] = this.deviceInfo.browser;
    // this.hashId["device"] = this.deviceName;
    // this.hashId["location"] = this.geolocation;
    // this.hashId["ip"] = this.ip;
    let logData = {
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      device: this.deviceName,
      location: this.geolocation,
      // ip:this.ip
    };
    console.log(hashId);
    this._app.finalSubmit(hashId, logData).subscribe(
      (res) => {
        console.log(res);
        this._data.toastrShow("Submitted", "info");
        // let alertObj = {
        // 	message: res["message"] || "SuccessFully Submitted",
        // 	status: "success",
        // 	autoDismiss: false,
        // 	timeToDismiss: 50000,
        // 	redirectUri : null
        // };
        // this._data.changeLoaderVisibility(false);
        // this.isProcessFlowCompleted = true;
        
        // alertObj.redirectUri = redirectUri ;
        // this._data.displayUniversalAlert(alertObj);
        // this.logActivity();
        
        // this.openMessageModal(
        //   "Η διαδικασία KYC σας έχει υποβληθεί με επιτυχία",
        //   redirectUri
        // );

        let body={
          gid: hashId, 
          sts: 8
        }
        console.log("before crypto api:",this.cryptoApi);
        this._app.cryptoStatusApi(this.cryptoApi,body).subscribe(result=>{
          console.log("before crypto api");
          let redirectUri = this.storage.redirectUri; //this._data.getCookiesByName("redirectUri");
          // this.openMessageModal(res["message"], redirectUri); //use this once BE is fixed

          this.openMessageModal("successfull", redirectUri);
        });
        
        this.logActivity();

        //redirect to redirect uri
        // let redirectUri = this._data.getCookiesByName("redirectUri");
        // setTimeout(() => {
        // 	window.open(redirectUri, "_self");
        // }, 3000);

        // this.router.navigate(['/user-home']);
      },
      (err) => {
        // this._data.changeLoaderVisibility(false);
      }
    );
  }

  openMessageModal(message, redirectUri) {
    // let message = "Something" ;
    let URL = redirectUri || "https://www.kychub.com/";
    const initialState = {
      title: "Kyc Completed",
      type: "completion-modal",
      backdrop: true,
      ignoreBackdropClick: true,
      message: message || "SuccessFully Submitted",
    };
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg completion-modal",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe((result) => {
      console.log("results", result);
      if (Object.keys(result).length) {
        console.log(result);
        if (result.shouldClose) {
          //   window.open(URL, "_self");
          if (this.isRedirect) {
            if (window.top) {
              window.top.location.href = URL;
            } else window.open(URL, "_self");
          } else {
            this.router.navigate(["/submitAfter"]);
          }
        }
      }
    });
  }

  gotoStep(stepNo) {
    // console.log();
    let state = this._kyc.getCurrentState(stepNo);
    let navRoute = this._kyc.getData("routesMap")[state["standardSlugGroup"]];
    console.log(state);
    this.router.navigate([navRoute]);
  }

  async logActivity() {
    let name = await this.storage.individualUserInfo; //this._data.getCookieObjectByName("individualUserInfo");

    let logData = {
      entity: "a",
      value: "b",
      message: `Final Submit Done`,
      page: `Preview Page`,
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      device: this.deviceName,
      source: "FEAS",
      geoLocation: this.geolocation,
    };

    this._app.saveUserActivityLogNew(logData, name["userName"]).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deviceInformation() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (this.deviceService.device == "unknown") {
      if (isDesktopDevice) {
        this.deviceName = "Desktop";
      }
      if (isMobile) {
        this.deviceName = "Mobile";
      }
      if (isTablet) {
        this.deviceName = "Tablet";
      }
    } else this.deviceName = this.deviceInfo.device;
    console.log(this.deviceInfo);
    this.getLocation();
    this.getIp();
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._app
          .getLocation(position.coords.latitude, position.coords.longitude)
          .subscribe((res) => {
            if (res.city == "") {
              this.geolocation = `${res.locality}, ${res.countryName}`;
            } else this.geolocation = `${res.city}, ${res.countryName}`;
          });
        // let geocoder = new google.maps.Geocoder();
      });
    } else {
      this.geolocation = null;
    }
  }
  getIp() {
    // this._app.getSystemIp().subscribe((res:any)=>{
    //   this.ip=res.ip;
    //   console.log("ip adress",this.ip)
    // });
  }

}
