import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { DataStorageService } from "./data-storage.service";
@Injectable({
  providedIn: "root",
})
export class DataService {
  public showLoader = new BehaviorSubject<boolean>(false);
  loaderInfo = this.showLoader.asObservable();
  public customData = new BehaviorSubject<any>("");

  constructor(
    private _cookieService: CookieService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private storage:DataStorageService
  ) {}

  getCookiesByName(slug: any) {
    return this._cookieService.get(slug);
  }

  getCookieObjectByName(slug: any) {
    return this._cookieService.getObject(slug);
  }

  setCookieByName(slug: any, value: any, expire?: any) {
    let expires;
    if (expire instanceof Date) {
      expires = expire;
    } else if (!expire) {
      expires = "";
    } else {
      expires = this.getCookieSessionTime(expire);
    }
    this._cookieService.put(slug, value, {
      expires: expires,
    });
  }

  setCookieObject(slug: any, value: any, expire?: any) {
    let expires;
    if (expire instanceof Date) {
      expires = expire;
    } else if (!expire) {
      expires = "";
    } else {
      expires = this.getCookieSessionTime(expire);
    }
    this._cookieService.putObject(slug, value, {
      expires: expires,
    });
  }

  removeAllCookie() {
    this._cookieService.removeAll();
  }

  getCookieSessionTime(days: any) {
    var session = new Date();
    days = days ? days : 1;
    session.setTime(session.getTime() + days * 24 * 60 * 60 * 1000);
    return session;
  }

  removeQueryParams(url) {
    let currentUrl = url.substring(0, url.indexOf("?")).length
      ? url.substring(0, url.indexOf("?"))
      : url;
    return currentUrl;
  }

  scrollTop() {
    window.scrollTo(0, 0);
    // (<any>)window.scrollTo(0,0);
  }

  transform(html) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    // return this.sanitizer.bypassSecurityTrustStyle(html);
    // return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    // return this.sanitizer.bypassSecurityTrustUrl(html);
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpeg;base64," + html
    );
  }

  isLoggedIn() {
    // if (this.getCookiesByName("feas_token")) {
    if (this.storage.feas_token) {
      return true;
    }
    return false;
  }

  //normalize date
  getNormalizedDate(inputDate) {
    console.log(inputDate)
    if (inputDate) {
      var tzoffset = new Date().getTimezoneOffset() * 60000;
      var localISOTime = new Date(inputDate - tzoffset)
        .toISOString()
        .split("T")[0];
      return localISOTime;
      console.log(localISOTime)
    }
    return inputDate;
  }

  toastrShow(message: any, type: any) {
    this.toastr.clear();
    switch (type) {
      case "info": {
        this.toastr.info("", message, { timeOut: 5000 });
        break;
      }
      case "error": {
        this.toastr.error("", message, { timeOut: 5000 });
        break;
      }
      case "success": {
        this.toastr.success("", message, { timeOut: 5000 });
        break;
      }
      default: {
        this.toastr.warning("", message, { timeOut: 5000 });
        break;
      }
    }
  }

  changeLoaderVisibility(shouldShow: boolean) {
    this.showLoader.next(shouldShow);
  }
  changeCustomizeData(customData:any){
  this.customData.next(customData);
  }
  }
