import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from "../../services/data.service";

@Component({
  selector: 'app-kyc-india-tnc',
  templateUrl: './kyc-india-tnc.component.html',
  styleUrls: ['./kyc-india-tnc.component.scss']
})
export class KycIndiaTncComponent implements OnInit {

  checkbox = false;
  customData:any;
  companyName:any;
  logo:any;
  constructor(
    public _data: DataService,
    private router:Router
  ) { }

  ngOnInit() {
    this._data.customData.subscribe(res=>{
      this.customData=res;
      if(this.customData!=undefined){
        this.customData.data.forEach(element => {
          if(element.keySlug=='small-logo'){
            this.logo=element.keyValue
          }
          else if(element.keySlug=='company-name'){
            this.companyName=element.keyValue
          }
        });
      }
    }) 
  }


  onClick(){
    this.router.navigate(['aadhar-verify'])
  }

  goBack(){
    this.router.navigate(['startkyc'])
  }
}
