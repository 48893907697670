import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { KycService } from "../../services/kyc.service";
import { AppService } from "../../services/app.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { IDDocument } from "src/app/models/document";
import { Check } from "src/app/models/check";
import { DataService } from "src/app/services/data.service";
import { Ng2DeviceService } from "ng2-device-detector";
import { DataStorageService } from "src/app/services/data-storage.service";

@Component({
  selector: "app-id-check-new",
  templateUrl: "./id-check-new.component.html",
  styleUrls: ["./id-check-new.component.scss"],
})
export class IdCheckNewComponent implements OnInit {
  @Input() stepno;
  @Output() completedStep = new EventEmitter<any>();
  nameDoc: string = "";
  docNumber: any;
  docType: any;
  file: any;

  countries: any[] = [];
  selectedCountry: any;

  extAllowed: any[] = ["image/png", "image/jpeg", "image/jpg", "pdf"];

  bsModalRef: BsModalRef;

  validDocument: any = [];
  idDoc: IDDocument = new IDDocument();
  uploadedFile1: any;
  checkObj: Check;
  startDate: any;
  endDate: any;
  deviceInfo: any;
  geolocation: string;
  deviceName: string;

  constructor(
    public _app: AppService,
    public _kyc: KycService,
    public _data: DataService,
    private deviceService: Ng2DeviceService,
    private storage: DataStorageService
  ) {
    this.deviceInformation();
  }

  ngOnInit() {
    // updateIndividualInfo ---  saving data --- not used now
    // getUserDocsDetails --- already upload doc
    // getAllowedDocs -- get docs
    // saveUserDoc -- upload file
    let checks = <Check[]>JSON.parse(this.storage.remoteChecks); //JSON.parse(localStorage.getItem('remoteChecks'));
    this.checkObj = checks.filter(
      (obj) => obj.standardCheck.slug == "id-doc-check"
    )[0];

    this.selectedCountry = this._kyc.getData("kycOriginCountry");
    this.getAllCountries();
    // this.getDocs();
  }
  getAllCountries() {
    this._app.getAllCountries().subscribe((response) => {
      this.countries = response.data;
    });
  }

  getDocs() {
    this._app.getAllowedDocs(this.selectedCountry).subscribe((res) => {
      let allowedDocs = res.data;
      this.validDocument = allowedDocs["validIdentityDocs"];
    });
  }

  //doc upload
  fileChangeListener($event, docSlug, docUrlType) {
    var image: any = new Image();
    var file: File = $event[0] ? $event[0] : $event.target.files[0];
    var name = file.name;

    this.uploadedFile1 = file;

    // this.uploadedDocType1 = docUrlType;
    if (file) {
      var docs;
      var fileReader = new FileReader();
      var base64;
      var that = this;
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        base64 = fileReader.result;
        // Print data in console
        that.idDoc[docUrlType] = base64;
        that.idDoc["docRearUrl"] = base64;
        docs = base64;
      };
      this.storage.document = docs;
      // Convert data to base64
      fileReader.readAsDataURL(file);
      // this.idDoc[docUrlType] = result["image"];
      this.idDoc["docSlug"] = docSlug;
    }
  }

  onFilesChange($event) {
    let doc = this.validDocument.find((item) => item.docName == this.docType);
    let docSlug = doc.docSlug;
    this.fileChangeListener($event, docSlug, "docFrontUrl");
  }
  //upload end

  stepComplete() {
    let dateStart = this.startDate ? new Date(this.startDate) : null;
    let dateStartOffset = dateStart
      ? dateStart.getTimezoneOffset() * 60000
      : null;
    let normalizeStartDate = dateStart
      ? new Date(dateStart.getTime() - dateStartOffset)
          .toISOString()
          .split("T")[0]
      : null;

    let dateExpiry = this.endDate ? new Date(this.endDate) : null;
    let dateExpiryOffset = dateExpiry
      ? dateExpiry.getTimezoneOffset() * 60000
      : null;
    let normalizeExpiryDate = dateExpiry
      ? new Date(dateExpiry.getTime() - dateExpiryOffset)
          .toISOString()
          .split("T")[0]
      : null;

    this._data.changeLoaderVisibility(true);
    this.idDoc["docType"] = "IDENTITY";
    let countryCode = this.countries.filter(
      (obj) => obj.countryName == this.selectedCountry
    );
    this.idDoc.docSerialNo = this.docNumber;
    this.idDoc["countryCode"] = countryCode[0].countryCode;
    this.idDoc.docExpiryDate = normalizeExpiryDate;
    this.idDoc.docIssueDate = normalizeStartDate;
    this.idDoc["os"] = this.deviceInfo.os;
    this.idDoc["browser"] = this.deviceInfo.browser;
    this.idDoc["device"] = this.deviceName;
    this.idDoc["location"] = this.geolocation;
    console.log(this.idDoc.docIssueDate);
    console.log(this.idDoc.docExpiryDate);
    this._app
      .saveUserDoc(this.idDoc, this.checkObj["endUserCheckID"])
      .subscribe((res) => {
        this._data.toastrShow(res.message, "info");
        this._data.changeLoaderVisibility(false);
        this.completedStep.emit(this.stepno);
      });
  }

  deviceInformation() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (this.deviceService.device == "unknown") {
      if (isDesktopDevice) {
        this.deviceName = "Desktop";
      }
      if (isMobile) {
        this.deviceName = "Mobile";
      }
      if (isTablet) {
        this.deviceName = "Tablet";
      }
    } else this.deviceName = this.deviceInfo.device;
    console.log(this.deviceInfo);
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._app
          .getLocation(position.coords.latitude, position.coords.longitude)
          .subscribe((res) => {
            if (res.city == "") {
              this.geolocation = `${res.locality}, ${res.countryName}`;
            } else this.geolocation = `${res.city}, ${res.countryName}`;
          });
      });
    } else {
      this.geolocation = null;
    }
  }
}
