interface standardCheckI{
	costPerRequest  : number ;
	isCorporateCheck: boolean ;
	isDashboardCheck: boolean ;
	isRemoteCheck   : boolean ;
	name            : string ;
	slug            : string ;
	standardSearch  : any ;
	status          : string ;
}

interface CheckI {
	appSource            : string
	clientAdminReview    : any
	completionDate       : string
	endUserCheckID       : string
	isRemoteCheck        : boolean
	lastModifiedDate     : string
	overriddenBy         : any
	overrideComment      : any
	registrationIp       : string
	reviewedByClientAdmin: any
	standardCheck        : standardCheckI 
	standardCheckGroup   : string // id-checks , address-checks, aml-checks
	startDate            : string
	verificationStatus   : string // CREATED , SUBMITTED , PENDING , REQUIRED_CONFIRMATION , FAILED , SUCCESS
}


export class Check implements CheckI{
	appSource            : string
	clientAdminReview    : any
	completionDate       : string
	endUserCheckID       : string
	isRemoteCheck        : boolean
	lastModifiedDate     : string
	overriddenBy         : any
	overrideComment      : any
	registrationIp       : string
	reviewedByClientAdmin: any
	standardCheck        : standardCheckI 
	standardCheckGroup   : string 
	startDate            : string
	verificationStatus   : string 

	constructor(check) {
        for(var key in check){
        	this[key] = check[key];
        }
    }
}
