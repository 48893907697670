export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  // params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
}

import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { DataService } from "../services/data.service";
import { DataStorageService } from "./data-storage.service";

@Injectable({
  providedIn: "root"
})
export class AppService {
  token: any;

  constructor(
    private http: HttpClient,
    private _data: DataService,
    private storage:DataStorageService,
    @Inject("API_URL") public API_URL: any,
  ) {
    this.token = this.storage.feas_token; //this._data.getCookiesByName("feas_token");
    console.log(this.token);
  }

  //Get i.e get Requests

  getLegacyDetails() {
    return this.http.get<any>(`${this.API_URL}/faas/userchecks`).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateIndividualInfo(obj, userName) {
    // let userName  = obj.userName ;
    return this.http
      .put<any>(`${this.API_URL}/faas/endusers/${userName}/info`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getClientConfig() {
    return this.http.get<any>(`${this.API_URL}/clients/faas/configs`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getLegacyClientConfig() {
    return this.http.get<any>(`${this.API_URL}/clients/faas/configs`).pipe(
      map(res => {
        return res;
      })
    );
  }


  getLegacyLangObj(langSlug) {
    return this.http
    .get<any>(`${this.API_URL}/languages?isoCode=${langSlug}`)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  getUserAddress(checklinkguid) {
    return this.http
      .get<any>(`${this.API_URL}/faas/individualaddress/${checklinkguid}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAllowedDocs(countryName) {
    return this.http.get<any>(`${this.API_URL}/country/${countryName}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getProvinceList(countryObj) {
    return this.http.post<any>(`${this.API_URL}/provinces`, countryObj).pipe(
      map(res => {
        return res;
      })
    );
  }

  getUserDocsDetails(checkGuid) {
    return this.http
      .get<any>(`${this.API_URL}/faas/userdocs/${checkGuid}/details`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getDocFromServer(checkGuid) {
    return this.http
      .get<any>(`${this.API_URL}/faas/userdocs/${checkGuid}/doc`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAllCountries() {
    return this.http.get<any>(`${this.API_URL}/country/all `).pipe(
      map(res => {
        return res;
      })
    );
  }

  getPreviewData(hashId) {
    return this.http
      .get<any>(`${this.API_URL}/faas/userchecks/${hashId}/preview`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getCheckLevels() {
    return this.http.get<any>(`${this.API_URL}/authlinkfaas/checklevels`).pipe(
      map(res => {
        return res;
      })
    );
  }

  verifyUserData(userObj) {
    return this.http
      .post<any>(`${this.API_URL}/authlinkfaas/validateuser`, userObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  registerFEASUser(userObj) {
    return this.http
      .post<any>(`${this.API_URL}/authlinkfaas/register`, userObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  uploadVideoAndSelfie(formData) {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
    // }
    // let headers: Headers = new Headers({'Content-Type':'multipart/form-data'});
    // let roa: Re = new RequestOptions({headers: headers});
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ "Accept": "application/json" })
    // }
    return this.http
      .post<any>(
        `${this.API_URL}/faas/userbiometric/liveness/video-selfie`,
        formData
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  finalSubmit(parentHashId ,logData) {
    return this.http
      .post<any>(`${this.API_URL}/faas/userchecks/${parentHashId}/submit`, logData)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //Create i.e POST Requests

  triggerBiometricResult(obj) {
    return this.http
      .post<any>(`${this.API_URL}/faas/userbiometric/liveness/result`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  saveUserAddress(addressObj, checklinkguid) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/individualaddress/${checklinkguid}`,
        addressObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  saveUserDoc(docObj, checklinkguid) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/userdocs/upload/${checklinkguid}`,
        docObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  sendBiometricImage(obj) {
    return this.http
      .post<any>(`${this.API_URL}/faas/userbiometric/liveness/input`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getLivenessDetails(livenessCheckID) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });

    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };

    // return this.http.get(
    //   `${this.API_URL}/userbiometric/doc/video/${checkGuid}` ,
    //      { headers: new HttpHeaders({"Content-Type": "application/octet-stream"}),
    //       responseType : "blob"}
    //    )
    //   .pipe(map(res => {
    //   return res;
    // }));

    return this.http
      .get(`${this.API_URL}/faas/userbiometric/doc/video/${livenessCheckID}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getSelfieDetails(selfieCheckID) {
    return this.http
      .get<any>(
        `${this.API_URL}/faas/userbiometric/doc/selfie/${selfieCheckID}`
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAudios() {
    return this.http.get<any>(`${this.API_URL}/faas/audio/v1`).pipe(
      map(res => {
        return res;
      })
    );
  }

  //activity log
  saveUserActivityLog(logObj, username) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/activity/add/${username}`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  // new activity log api 
  saveUserActivityLogNew(logObj, username) {
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/v1.0/activity/add/${username}`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  saveUserActivityLogGetstarted(logObj, username) {
    console.log("username",username);
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/${username}/getstarted`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  saveUserActivityLogAgrement(logObj, username) {
    console.log("username",username);
    return this.http
      .post<any>(
        `${this.API_URL}/faas/endusers/${username}/aggrements`,
        logObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=28.644800&longitude=77.216721&localityLanguage=en
  getLocation(lat,long) {
    return this.http.get<any>(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=all`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getSystemIp() {
    return this.http.get("https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json");
  }
  getClientCustomizeData(){
    return this.http.post<any>(`${this.API_URL}/faas/india-kyc/feas-config`,null)
    .pipe(
      map(res =>{
        return res;
      })
    )
  }
  verifyUPI(checkUserId,obj){
    return this.http.post<any>(`${this.API_URL}/faas/india-kyc/upi/verify/`+checkUserId,obj)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  verifyBank(checkUserId,obj){
    return this.http.post<any>(`${this.API_URL}/faas/india-kyc/bank/verify/`+checkUserId,obj)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  facematch(checkUserId,formData){
    return this.http.post<any>(`${this.API_URL}/faas/india-kyc/face/match/`+checkUserId,formData)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  cryptoStatusApi(cryptoApi,body){
    console.log("crypto api in service: ",cryptoApi);
    return this.http.post<any>(`${cryptoApi}`,body)
    .pipe(
      map(res => {
        return res;
      })
    );
  }
}
