import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { DataService } from "../services/data.service";
import { DataStorageService } from "../services/data-storage.service";

// import { AuthenticationService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _data: DataService,
    private _router: Router, // private authenticationService: AuthenticationService
    private storage:DataStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);
        console.log(err.status);
        if (err.status == 401 || err.status == 403) {
          // auto logout if 401 response returned from api
          // this.authenticationService.logout();
          // if(localStorage.getItem("counter")!=null && Number(localStorage.getItem("counter"))>=9){
          if(this.storage.counter!=null && Number(this.storage.counter)>=9){ 
            // localStorage.removeItem("counter");
            this.storage.counter=undefined;
            this._data.toastrShow("KYC Link is expired, please contact your admin to regenerate a new link.", "error");
            let errMessage =
              err.error.message || err.statusText || err["message"];
            let alertObj = {
              message: errMessage,
              status: "danger",
              autoDismiss: true,
              timeToDismiss: 2000,
            };
            // this._data.changePublicErr(errMessage);
            // this._data.displayUniversalAlert(alertObj);
            this.storage.removeAllCookie();
            this._data.changeLoaderVisibility(false);
            this._router.navigate(["/access-denied"]);
          }
         

          // else if(localStorage.getItem("counter")==null){
          else if(this.storage.counter==null){
            var count=1
            // localStorage.setItem("counter",count.toString())
            this.storage.counter=count.toString();
            setTimeout(this.reload,5000)
          }
        //  else if(Number(localStorage.getItem("counter"))<15){
          else if(Number(this.storage.counter)<15){
            // var count=Number(localStorage.getItem("counter"))
            var count=Number(this.storage.counter);
            count=count+1;
            // localStorage.removeItem("counter")
            this.storage.counter=undefined;
            // localStorage.setItem("counter",count.toString())
            this.storage.counter=count.toString();
          } 

        }
        

      else if (err.status !== 404 ) {
          const error = err.error.message || err.statusText || err["message"];
          this._data.toastrShow(error, "error");
          let alertObj = {
            message: error,
            status: "danger",
            autoDismiss: true,
            timeToDismiss: 5000,
          };
          // this._data.displayUniversalAlert(alertObj);
          this._data.changeLoaderVisibility(false);
        }

        const error = err.error.message || err.statusText || err["message"];
        return throwError(error);
      })
    );
  }
  reload() {
    document.location.reload();
  }
}
