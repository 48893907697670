import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-flow-tnc',
  templateUrl: './custom-flow-tnc.component.html',
  styleUrls: ['./custom-flow-tnc.component.scss']
})
export class CustomFlowTncComponent implements OnInit {
  tnc:boolean=true;
  constructor( private router:Router) { }

  ngOnInit() {
  }

  toggleTnc(){
    this.tnc=!this.tnc;
  }

  goToNext(){
    this.router.navigateByUrl('/kyc-verification');
  }
}
