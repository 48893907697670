import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { UiStateService } from "../../services/ui-state.service";
import { IndividualUserInfo } from "../../models/individual-user-info";
import { Check } from "../../models/check";
import { CustomFormsModule } from "ng2-validation";
import { first } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { Ng2DeviceService } from "ng2-device-detector";
import { DataStorageService } from "src/app/services/data-storage.service";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  token: string;
  authToken: string;
  countries: any[] = [];

  allDataLoaded: boolean = false;
  individualUserInfo: IndividualUserInfo;
  checks: Check[] = [];
  themingInfo: any;

  checkLinkGUID: string;
  kycOriginCountry: string;

  isCountryUpdatable: boolean;
  isProfileUpdatable: boolean;
  redirectUrl: any;

  clientDetails: any;
  configData:any;
  //Mobile Vars
  isMobile: boolean;

  audios: any[] = [];
  deviceInfo: any;
  deviceName: string;
  geolocation: string;
  ip: any;
  kycType: any;
  showCountry: boolean;

  constructor(
    public _app: AppService,
    public _data: DataService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public uiStateService: UiStateService,
    public cdRef: ChangeDetectorRef,
    private deviceService: Ng2DeviceService,
    private storage: DataStorageService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) {
        console.log("params.token");
        console.log(params.token);
        this.token = params.token;
        this.storage.removeAllCookie(); //Remove All previous sessions
        // this._data.setCookieByName("feas_token", this.token);

        this.storage.feas_token = this.token;
        this.getAllDetails();
        this.getAudios();
      }
      if (params.authtoken) {
        console.log("params.authtoken");
        console.log(params.authtoken);
        this.authToken = params.authtoken;
        this.storage.removeAllCookie(); //Remove All previous sessions
        // this._data.setCookieByName("auth_token", this.authToken);
        this.storage.auth_token = this.authToken;
      }
    });
    this.deviceInformation();

    // this.logActivity()
  }

  ngOnInit() {
    this.getAllCountries();
    this.isMobile = this.uiStateService.getIsMobileResolution();
    console.log("isMobile: ", this.isMobile);
    // this.logActivity()
    // this.getIp();

    this.activatedRoute.queryParams.subscribe(({type}) => {
      this.kycType =type;
      // if(this.kycType=="digilocker-check"){
        // localStorage.setItem("feasType",this.kycType);
        this.storage.feasType = this.kycType;
      // }
    });

   
    
  }

  getAllCountries() {
    this._app.getAllCountries().subscribe((response) => {
      this.countries = response["data"];
      // localStorage.setItem("countries", JSON.stringify(this.countries));
      this.storage.countries = JSON.stringify(this.countries);
    });
  }

  getAudios() {
    var clientAudios$ = this._app.getAudios();
    clientAudios$.subscribe((response) => {
      // this.audios = response.data ;
      var audios = response.data;
      for (var key in audios) {
        audios[key] = `data:audio/wav;base64,${audios[key]}`;
      }
      this.audios = audios;
      // localStorage.setItem("audios", JSON.stringify(this.audios));
      this.storage.audios = JSON.stringify(this.audios);
    });
  }

  getAllDetails() {
    var clientConfig$ = this._app.getLegacyClientConfig();
    var clientDetails$ = this._app.getLegacyDetails();
    forkJoin([clientConfig$, clientDetails$]).subscribe(
      (response) => {
        this.allDataLoaded = true;
        // this.themingInfo = response[0].data.dashboardTheme
        //   ? JSON.parse(response[0].data.dashboardTheme)
        //   : null;
        this.clientDetails = response[1].data;
        this.configData = response[0].data
        this.clientDetails.feAsTheme = JSON.parse(response[0].data.feAsTheme);
        console.log(response);
        this.themingInfo = {};
        this.prepareChecksModel(response[1]);
        this.prepareIndividualModel(response[1]);
        // this.logActivity();
        this.checkLinkGUID = response[1].data.checkLinkGUID;
        this.kycOriginCountry = response[1].data.residenceCountry;
        this.isCountryUpdatable = response[1].data.isCountryUpdatable;
        this.isProfileUpdatable = response[1].data.isProfileUpdatable;
        this.redirectUrl = response[0].data.redirectUrl;
        this.showCountry = response[0].data["allowCountryChange"];
        // let checks= response[1].data.checks.filter(element=>{
        //   return element.standardCheck.slug=="id-doc-check" || element.standardCheck.slug=="id-doc-check-2"
        // })
        // console.log("idCOint in welcome:",checks);
        // this.storage.idCount=checks.length;
        // this._data.setCookieByName(
        //   "resCountry",
        //   response[1].data.residenceCountry
        // );
        this.storage.resCountry=response[1].data.residenceCountry;
        this.saveData();
        console.log(response[0]);
        console.log(response[1]);
        console.log(this.themingInfo);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  prepareIndividualModel(response) {
    this.individualUserInfo = new IndividualUserInfo(
      response.data.individualUserInfo
    );
    this.individualUserInfo.userName = response.data.userName;
  }

  prepareChecksModel(response) {
    if (response.data.checks && response.data.checks.length) {
      for (var i = 0; i < response.data.checks.length; i++) {
        this.checks.push(new Check(response.data.checks[i]));
      }
    }
  }

  saveData() {
    // this._data.setCookieByName("isCountryUpdatable", this.isCountryUpdatable);
    this.storage.isCountryUpdatable = this.isCountryUpdatable;
    // this._data.setCookieByName("isProfileUpdatable", this.isProfileUpdatable);
    this.storage.isProfileUpdatable = this.isProfileUpdatable;
    // this._data.setCookieByName("redirectUri", this.redirectUrl);
    this.storage.redirectUri = this.redirectUrl;
    // this._data.setCookieObject("kycOriginCountry", this.kycOriginCountry);
    this.storage.kycOriginCountry = this.kycOriginCountry;
    // this._data.setCookieObject("checkLinkGUID", this.checkLinkGUID);
    this.storage.checkLinkGUID = this.checkLinkGUID;
    // this._data.setCookieObject("individualUserInfo", this.individualUserInfo);
    this.storage.individualUserInfo = this.individualUserInfo;
    // this._data.setCookieByName("allowCountryChange",this.showCountry);
    this.storage.allowCountryChange = this.showCountry;
    // this.storage.othersRequired=this.configData['othersRequired'];
    // this._data.setCookieByName("mandatoryDocCountry",this.clientDetails['mandatoryDocCountry']);
    this.storage.mandatoryDocCountry =this.configData["mandatoryDocCountry"];
    // this._data.setCookieByName("mandatoryFirstDocSlug",this.clientDetails['mandatoryFirstDocSlug']);
    this.storage.mandatoryFirstDocSlug =this.configData["mandatoryFirstDocSlug"];
    console.log("man doc in welcome: ",this.storage.mandatoryDocCountry," ",this.storage.mandatoryFirstDocSlug );
    // this._data.setCookieByName("redirection",this.clientDetails['redirection']);
    this.storage.redirection = this.configData["redirection"];
    this.storage.clientsApiEndpoint=this.configData["clientsApiEndpoint"];
    // localStorage.setItem("remoteChecks", JSON.stringify(this.checks));
    this.storage.remoteChecks = JSON.stringify(this.checks);
    // localStorage.setItem("themingInfo", JSON.stringify(this.themingInfo));
    this.storage.themingInfo = JSON.stringify(this.themingInfo);
  }
  logActivity() {
    let logData = {
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      device: this.deviceName,
      location: this.geolocation,
      // ip:this.ip
    };
    console.log(this.individualUserInfo);
    this._app
      .saveUserActivityLogGetstarted(logData, this.individualUserInfo.userName)
      .subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  getIp() {
    // this._app.getSystemIp().subscribe((res:any)=>{
    //   this.ip=res.ip;
    //   console.log("ip adress",this.ip)
    // });
  }
  deviceInformation() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (this.deviceService.device == "unknown") {
      if (isDesktopDevice) {
        this.deviceName = "Desktop";
      }
      if (isMobile) {
        this.deviceName = "Mobile";
      }
      if (isTablet) {
        this.deviceName = "Tablet";
      }
    } else this.deviceName = this.deviceInfo.device;
    console.log("device info", this.deviceName);
    this.getLocation();
    this.getIp();
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._app
          .getLocation(position.coords.latitude, position.coords.longitude)
          .subscribe((res) => {
            if (res.city == "") {
              this.geolocation = `${res.locality}, ${res.countryName}`;
            } else this.geolocation = `${res.city}, ${res.countryName}`;

            console.log("location", this.geolocation);
          });

        // this.geolocation=`lat:${position.coords.latitude},lon:${position.coords.longitude}`;
        // console.log("geolcation",this.geolocation);
        // let geocoder = new google.maps.Geocoder();
      });
    } else {
      this.geolocation = null;
    }
  }

  onClick() {
    // this.activatedRoute.queryParams.subscribe(({type}) => {
    var isPoc = this.clientDetails.checks.find(
      (item) =>
        item.standardCheck.slug == "upi-verification" ||
        item.standardCheck.slug == "bank-verification"
    );

    if (this.kycType != undefined && this.kycType === "india-kyc") {
      console.log("param type if:", this.kycType);
      this.router.navigate(["kycindia-tnc"]);
    } else if (isPoc != undefined) {
      this.router.navigate(["kyc-tnc"]);
    } else {
      console.log("param type else:", this.kycType);
      this.router.navigate(["terms"]);
    }
    // })
  }
}
