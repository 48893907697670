import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../app/services/data.service';

@Component({
  selector: 'app-aadhar-feas',
  templateUrl: './aadhar-feas.component.html',
  styleUrls: ['./aadhar-feas.component.scss']
})
export class AadharFeasComponent implements OnInit {

  step=1;
  state:any;
  customData:any;
  companyName:any;
  logo:any;
  branding="true";
  constructor(public _data: DataService) { }

  ngOnInit() {
    this._data.customData.subscribe(res=>{
      this.customData=res;
      if(this.customData!=undefined){
    this.customData.data.forEach(element => {
      if(element.keySlug=='small-logo'){
        this.logo=element.keyValue
      }
      else if(element.keySlug=='company-name'){
        this.companyName=element.keyValue
      }
      else if(element.keySlug=='branding'){
        this.branding=element.keyValue;
      }
    });
  }
  })
  }

  changeStep(event){
    this.step=event.step;
    this.state=event.state?event.state:undefined;
    console.log("parent event: ",event.state);
  }
  goBack(){
    if(this.step>1)
      this.step--;
  }
}
