import { Injectable } from '@angular/core';
import { CanActivate, Router , ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable , of } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuard implements CanActivate {

	constructor(
        private _router: Router, 
        private data: DataService
    ) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		let isLoggedIn = this.data.isLoggedIn();
        // this.data.changeToken(token);
        if(isLoggedIn){
            this._router.navigate(['/startkyc']);
            return of(false);
        } else {
            return of(true);
        }
	}
}
