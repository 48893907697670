import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../../app/services/data.service';
import { AppService } from '../../../app/services/app.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from "ngx-bootstrap/modal";
import { FeasModalComponent } from "../../shared/feas-modal/feas-modal.component";
import { DataStorageService } from 'src/app/services/data-storage.service';

@Component({
  selector: 'app-bank-verification',
  templateUrl: './bank-verification.component.html',
  styleUrls: ['./bank-verification.component.scss']
})
export class BankVerificationComponent implements OnInit {

  @Input() stepno;
  @Output() completedStep=new EventEmitter<any>(); 
  accNumber:any;
  accName:any;
  ifsc:any;
  bsModalRef: BsModalRef;
  upiId:any;
  isUpi=false;
  bankitem=false;
  upiitem=false;
  upicheckId;
  bankcheckId
  constructor(public _app: AppService,public _data:DataService,private modalService: BsModalService, private storage:DataStorageService) { }
checks=[];
  ngOnInit() {
    let checks =JSON.parse(this.storage.remoteChecks);//JSON.parse(localStorage.getItem('remoteChecks'));
    checks.forEach(element => {
      if(element.standardCheck.slug=='upi-verification'){
       this.upiitem=true;
       this.upicheckId=element.endUserCheckID

      }else if(element.standardCheck.slug=='bank-verification'){
      this.bankitem=true;
      this.bankcheckId=element.endUserCheckID;
      }
    });
    if(this.upiitem && !this.bankitem){
      this.isUpi=true;
    }
  }
  toggleTab(tabName){
    if(tabName=='bank'){
      this.isUpi=false;
    }
    else{
      this.isUpi=true;
    }
  }
  stepComplete(){
    this._data.changeLoaderVisibility(true)
    if(this.isUpi){
      let upi={'vpa':this.upiId}
      this._app.verifyUPI(this.upicheckId,upi).subscribe(res=>{
        this._data.toastrShow(res.data.message, "info");
        this._data.changeLoaderVisibility(false);
        this.openMessageModal("KYC Completed","")
      })
    }
    else if(!this.isUpi){
      let bank={'account_number':this.accNumber,'name':this.accName,'ifsc':this.ifsc}
      this._app.verifyBank(this.bankcheckId,bank).subscribe(res=>{
        this._data.toastrShow(res.data.message, "info");
        this._data.changeLoaderVisibility(false);
        this.openMessageModal("KYC Completed","")
      })
    }
  }
  openMessageModal(message, redirectUri) {
    // let message = "Something" ;
    let URL = redirectUri || "https://www.kychub.com/";
    const initialState = {
      title: "Kyc Completed",
      type: "completion-modal",
      backdrop: true,
      ignoreBackdropClick: true,
      message: message || "SuccessFully Submitted"
    };
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg completion-modal"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      console.log("results", result);
      if (Object.keys(result).length) {
        console.log(result);
        if (result.shouldClose) {
          //   window.open(URL, "_self");
          if (window.top) {
            window.top.location.href = URL;
          } else window.open(URL, "_self");
        }
      }
    });
  }
}
