import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-flow',
  templateUrl: './custom-flow.component.html',
  styleUrls: ['./custom-flow.component.scss']
})
export class CustomFlowComponent implements OnInit {

  activeStep=1;
  proceedNext:boolean=false;
  constructor() { }

  ngOnInit() {
  }

  goToNext(){
    this.activeStep++;
  }
}
