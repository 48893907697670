import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BehaviorSubject } from "rxjs";
import * as faceapi from "face-api.js";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ImageCropperComponent } from "ngx-image-cropper";

import { SelfieService } from "../../services/selfie.service";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";

import * as RecordRTC from "recordrtc";
import { Ng2DeviceService } from "ng2-device-detector";
import { DataStorageService } from "src/app/services/data-storage.service";
@Component({
  selector: "app-feas-modal",
  templateUrl: "./feas-modal.component.html",
  styleUrls: ["./feas-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FeasModalComponent implements OnInit {
  //Generic Variables
  title: string;
  counter1 = 0;
  type:
    | "show-image"
    | "crop-image"
    | "record-video"
    | "completion-modal"
    | "capture-doc"
    | "crop-image-b64"
    | "confirmation-modal"
    | "face-check";

  public onClose = new BehaviorSubject<any>({});

  //variables for "show-image"
  image: string;

  //variables for "completion-modal"
  message: any;

  //vars for crop image
  imageChangedEvent: any;
  croppedImage: any;
  loadedImageEvent: any;
  showCropper: boolean = false;
  imageFile: any = null;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  //vars for video
  // videoStream : any ;
  videoRecorder: any;
  recordedVideo: any;
  selfie: any;
  selfieConfigData: any;
  shouldRetry: boolean = false;
  imgAsb64: any;
  shouldDisableRecordBtn: boolean = false;
  counter: any = 1;
  isVidForcedOff: boolean = false;
  waitFor: number = 3;

  //helper Arr
  // helperBackgroundImage  : any = null;
  // helperText             : any = null;

  // bgArr                  : any[] = [6, 1, 12, 6];
  // instructionArr         : any[] = [
  //     "Please Look Straight At The Camera",
  //     "Please Turn Your Face To The Right",
  //     "Please Turn Your Face To The Left",
  //     "Please Look Straight At The Camera & Blink"
  // ];
  // audioArr               : any[] = [
  //     "assets/audio/center.mp3",
  //     "assets/audio/right.mp3",
  //     "assets/audio/left.mp3",
  //     "assets/audio/blink.mp3"
  // ];
  showVideos: any = {
    showLiveWebCam: false,
    showRecordedWebCam: false,
  };
  geolocation: any;
  deviceInfo: any;
  deviceName: string;
  ip: any;
  faceCheckId;
  @ViewChild("video")
  public video: ElementRef;
  @ViewChild("canvas")
  public canvasRef: ElementRef;
  stream: any;
  detection: any;
  resizedDetections: any;
  canvas: any;
  canvasEl: any;
  @ViewChild("canvas2")
  public canvas2: ElementRef;
  displaySize: any;
  videoInput: any;
  uploadedDoc = this.storage.document;
  WIDTH = 440;
  HEIGHT = 280;
  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public _selfie: SelfieService,
    public _app: AppService,
    public _data: DataService,
    private deviceService: Ng2DeviceService,
    private elRef: ElementRef,
    private storage: DataStorageService
  ) {}

  async ngOnInit() {
    if (this.type == "crop-image") {
      console.log(this.loadedImageEvent);
      var image: any = new Image();
      var file: File = this.loadedImageEvent[0]
        ? this.loadedImageEvent[0]
        : this.loadedImageEvent.target.files[0];
      this.imageFile = this.loadedImageEvent[0]
        ? this.loadedImageEvent[0]
        : this.loadedImageEvent.target.files[0];
      var name = file.name;
      if (file) {
        var reader = new FileReader();
        var that = this;
        reader.onloadend = function () {
          // console.log(reader.result);
        };
        reader.readAsDataURL(file);
      }
      console.log("::::::::::::::::::::::::::::::", this.imageFile);
    }

    if (this.type == "crop-image-b64") {
      this.imageFile = this.imgAsb64;
      console.log("Speaking from feas modal", this.imgAsb64);
    }

    if (this.type == "record-video") {
      this._selfie.init();
      //   console.log(":::::::::::::::", this.selfieConfigData);
      //   setInterval(() => {
      //     this._data.toastrShow("Bello", "error");
      //   }, 2000);
    }
    if (this.type == "face-check") {
      let checks = JSON.parse(this.storage.remoteChecks); //JSON.parse(localStorage.getItem('remoteChecks'));
      checks.forEach((element) => {
        if (element.standardCheck.slug == "face-match") {
          this.faceCheckId = element.endUserCheckID;
        }
      });

      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri("../../assets/models"),
        await faceapi.nets.faceLandmark68Net.loadFromUri("../../assets/models"),
        await faceapi.nets.faceRecognitionNet.loadFromUri(
          "../../assets/models"
        ),
        await faceapi.nets.faceExpressionNet.loadFromUri("../../assets/models"),
      ]).then(() => this.startVideo());
    }
    this.deviceInformation();
  }

  closeModal(data?: any) {
    console.log("CLoseddd!");
    if (this.type === "capture-doc") {
      this._selfie.stopVideo();
    }

    if (this.type === "record-video") {
      this.stopVideo();
    }
    if (this.type === "face-check") {
      let stream = this.videoInput.srcObject;
      if (stream != undefined) {
        let tracks = stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
      }
    }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  closeProcess() {
    let obj = {
      shouldClose: true,
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  //functions for crop image

  cropImage() {
    let data = {
      image: this.croppedImage,
    };
    this.closeModal(data);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log("Image loaded");
  }
  cropperReady() {
    console.log("Cropper ready");
  }
  loadImageFailed() {
    console.log("Load failed");
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  ////record-video
  facingMode = "user";
  constraints = {
    audio: false,
    video: {
      facingMode: this.facingMode,
    },
  };
  checkForPermission() {
    navigator.mediaDevices.getUserMedia(this.constraints).then(
      (successCallback) => {
        console.log("runn callback", successCallback);
        this.showVideos.showLiveWebCam = true;
        this.showVideos.showRecordedWebCam = false;
        setTimeout(() => {
          var video = <HTMLMediaElement>(
            document.getElementById("liveWebcamVideo")
          );
          video.srcObject = successCallback;
          video.play();
          this._selfie.videoStream = successCallback;
          if (this.type !== "capture-doc") {
            //   this.setDirectionHelpers();
            this.waitText();
          }
          this.startVideoRecording();
          this.shouldDisableRecordBtn = true;
        }, 0);
      },
      (errorCallback) => {
        let alertObj = {
          message: "Please Allow Camera",
          status: "danger",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        console.log(alertObj);
        // this._data.displayUniversalAlert(alertObj);
        // this._data.toastrShow("Please Allow Camera", "info");
      }
    );
  }

  startVideoRecording() {
    this.videoRecorder = RecordRTC(this._selfie.videoStream, {
      mimeType: "video/*;codecs=h264",
      frameInterval: 45,
      frameRate: 30,
    });
    this.videoRecorder.startRecording();
  }

  waitText() {
    let count = this.waitFor + 1;
    var that = this;
    let interval = setInterval(() => {
      if (count > 1) {
        count--;
        that._selfie.helperText = `Recording starts in ${count} seconds`;
        console.log(count);
      } else {
        clearInterval(interval);
        that.setDirectionHelpers();
      }
    }, 1000);
  }

  setDirectionHelpers() {
    var that = this;

    var counter = 0;
    var timer = 0;
    var interval = setInterval(() => {
      that._selfie.helperBackgroundImage = `https://images.obsassets.com/kychome/direction/${that._selfie.bgArr[counter]}.jpeg`;
      that._selfie.helperText = `${that._selfie.instructionArr[counter]}`;
      this._selfie.audioStream = new Audio(`${that._selfie.audioArr[counter]}`);
      this._selfie.audioStream.play();
      counter++;

      if (counter === 1) {
        console.log("reached completions 1");
        that.setSubsequentDirections();
        clearInterval(interval);
      }
    }, 0);
  }

  setSubsequentDirections() {
    var that = this;
    var timer = 0;
    var interval = setInterval(() => {
      if (!that.isVidForcedOff) {
        that._selfie.helperBackgroundImage = `https://images.obsassets.com/kychome/direction/${
          that._selfie.bgArr[that.counter]
        }.jpeg`;
        that._selfie.helperText = `${
          that._selfie.instructionArr[that.counter]
        }`;
        this._selfie.audioStream = new Audio(
          `${that._selfie.audioArr[that.counter]}`
        );
        this._selfie.audioStream.play();
        that.counter++;
        console.log(":::::::::::::::::::::::::", that.counter);
        if (that.counter == 2) {
          that.triggerSnapshot();
        }
        if (that.counter == 5) {
          console.log("reached completions 2");
          that.stopVideoRecording();
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 4000);
  }
  stopVideoRecording() {
    var that = this;
    this.showVideos.showLiveWebCam = false;
    this.showVideos.showRecordedWebCam = true;
    this.shouldDisableRecordBtn = false;
    that.videoRecorder.stopRecording(function () {
      let blob = that.videoRecorder.getBlob();
      that.invokeSaveAsDialog(blob);
      console.log(blob);
    });
    that._selfie.videoStream.getTracks().forEach(function (track) {
      track.stop();
    });
    // that._selfie.videoStream.stop();
  }

  invokeSaveAsDialog(blob) {
    // this.videoBlob = blob ;
    console.log(blob);
    // this.shouldShowFinalVideo = true ;
    // var videoSection = document.querySelector("#userVideoSection");
    // (<HTMLElement>videoSection).style.display = 'block' ;
    var video = document.getElementById("recordedWebcamVideo");
    if (video) {
      video["src"] = window.URL.createObjectURL(blob);
    }

    this.recordedVideo = blob;

    // var video = <HTMLMediaElement>document.getElementById('videoo');
    //     video.srcObject = successCallback;
    //     video.play();
  }

  triggerSnapshot() {
    var canvas = <HTMLCanvasElement>document.getElementById("snapCanvas");
    var context = canvas.getContext("2d");
    var video = <any>document.getElementById("liveWebcamVideo");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // context.drawImage(video, 0, 0, 640, 480, 0, 0, 640, 480);

    // var data = canvas.toDataURL("image/png");
    var data = canvas.toDataURL("image/jpeg");
    this.selfie = data;

    if (this.type === "capture-doc") {
      this.closeModal(this.selfie);
    }
    // console.log(data);
  }

  stopVideo() {
    this.counter = 1;
    this.isVidForcedOff = true;
    this._selfie.helperText = "";
    this._selfie.stopVideo();
    this._selfie.stopAudio();
  }

  dataURLtoFile(dataURI) {
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  onSubmitVideo(event) {
    this.recordedVideo = event.srcElement.files.item(0);
    //alert(this.recordedVideo);
  }

  onSubmitImage(event) {
    this.selfie = event.srcElement.files.item(0);
    //alert(this.selfie);
  }
  uploadVideoAndSelfienew() {
    //alert(this.deviceInfo.browser);
    //alert(this.deviceName);
    let endUserDeviceDetails = {
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      device: this.deviceName,
      location: this.geolocation,
      // ip:this.ip
    };
    var formData = new FormData();
    // var image = this.dataURLtoFile(this.selfie, "hero");
    // formData.append("selfie", this.dataURLtoFile(this.selfie));
    formData.append("selfie", this.selfie);
    formData.append("imageType", "jpg");
    formData.append("video", this.recordedVideo);
    formData.append("videoType", "mov");
    formData.append("selfiId", this.selfieConfigData.selfie.endUserCheckID);
    formData.append(
      "livenessId",
      this.selfieConfigData.liveness.endUserCheckID
    );
    console.log("enduser data", JSON.stringify(endUserDeviceDetails));
    formData.append(
      "endUserDeviceDetails",
      JSON.stringify(endUserDeviceDetails)
    );
    this._data.changeLoaderVisibility(true);
    this._app.uploadVideoAndSelfie(formData).subscribe(
      (res) => {
        console.log(res);

        this._data.changeLoaderVisibility(false);
        let alertObj = {
          message: res.message,
          status: "success",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        this.closeModal(res);
      },
      (err) => {
        this._data.changeLoaderVisibility(false);
        this.shouldRetry = true;
        // alert("error");
      }
    );
  }

  uploadVideoAndSelfie() {
    let endUserDeviceDetails = {
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      device: this.deviceName,
      location: this.geolocation,
      // ip:this.ip
    };
    var formData = new FormData();
    var image = this.dataURLtoFile(this.selfie);
    console.log("form data variable :   " + formData.toString());
    formData.append("selfie", image);
    // formData.append('selfie', this.webcamImage);
    formData.append("video", this.recordedVideo);
    formData.append("imageType", "jpeg");
    formData.append("videoType", "webcam");
    formData.append("selfiId", this.selfieConfigData.selfie.endUserCheckID);
    formData.append(
      "livenessId",
      this.selfieConfigData.liveness.endUserCheckID
    );
    console.log("enduser data", JSON.stringify(endUserDeviceDetails));
    formData.append(
      "endUserDeviceDetails",
      JSON.stringify(endUserDeviceDetails)
    );
    this._data.changeLoaderVisibility(true);
    this._app.uploadVideoAndSelfie(formData).subscribe(
      (res) => {
        console.log(res);
        // this.isUpdate = true ;
        this._data.changeLoaderVisibility(false);
        let alertObj = {
          message: res.message,
          status: "success",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        this.closeModal(res);
        // this._data.displayUniversalAlert(alertObj);

        //automatically goto next step on success
        // this.stopCamActivity();
        // this.goToStep('next');
        // this.goToStep('isFinalStep');
      },
      (err) => {
        this._data.changeLoaderVisibility(false);
        // this.closeModal();
        this.shouldRetry = true;
        // alert("error");
      }
    );
  }

  // End user Activity log
  getIp() {
    // this._app.getSystemIp().subscribe((res:any)=>{
    //   this.ip=res.ip;
    //   console.log("ip adress",this.ip)
    // });
  }
  deviceInformation() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (this.deviceService.device == "unknown") {
      if (isDesktopDevice) {
        this.deviceName = "Desktop";
      }
      if (isMobile) {
        this.deviceName = "Mobile";
      }
      if (isTablet) {
        this.deviceName = "Tablet";
      }
    } else this.deviceName = this.deviceInfo.device;
    console.log("device info", this.deviceName);
    this.getLocation();
    this.getIp();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._app
          .getLocation(position.coords.latitude, position.coords.longitude)
          .subscribe((res) => {
            if (res.city == "") {
              this.geolocation = `${res.locality}, ${res.countryName}`;
            } else this.geolocation = `${res.city}, ${res.countryName}`;

            console.log("location", this.geolocation);
          });

        // this.geolocation=`lat:${position.coords.latitude},lon:${position.coords.longitude}`;
        // console.log("geolcation",this.geolocation);
        // let geocoder = new google.maps.Geocoder();
      });
    } else {
      this.geolocation = null;
    }
  }
  startVideo() {
    this.videoInput = this.video.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: {} }).then((stream) => {
        try {
          this.videoInput.srcObject = stream;
        } catch (error) {
          console.log(error);
        }
        this.detect_Faces();
      });
    }
  }

  async detect_Faces() {
    this.elRef.nativeElement
      .querySelector("video")
      .addEventListener("play", async () => {
        this.canvas = await faceapi.createCanvasFromMedia(this.videoInput);
        this.canvasEl = this.canvasRef.nativeElement;
        this.canvasEl.appendChild(this.canvas);
        this.canvas.setAttribute("id", "canvass");
        this.canvas.setAttribute(
          "style",
          `position: fixed;
        top: 0;
        left: 0;`
        );
        this.displaySize = {
          width: this.videoInput.width,
          height: this.videoInput.height,
        };
        faceapi.matchDimensions(this.canvas, this.displaySize);
        var id = setInterval(async () => {
          this.detection = await faceapi
            .detectAllFaces(
              this.videoInput,
              new faceapi.TinyFaceDetectorOptions()
            )
            .withFaceLandmarks()
            .withFaceExpressions();
          this.resizedDetections = faceapi.resizeResults(
            this.detection,
            this.displaySize
          );
          this.canvas
            .getContext("2d")
            .clearRect(0, 0, this.canvas.width, this.canvas.height);
          faceapi.draw.drawDetections(this.canvas, this.resizedDetections);
          if (
            this.detection[0].detection._score > 0.8 &&
            this.detection != undefined
          ) {
            clearInterval(id);
            this.counter1++;
            var canvas2 = <HTMLCanvasElement>document.getElementById("canvas2");
            var context = canvas2.getContext("2d");
            var video = <any>document.getElementById("video");
            context.drawImage(video, 0, 0, canvas2.width, canvas2.height);
            var data = canvas2.toDataURL("image/jpeg");
            let formData = { img1: this.uploadedDoc, img2: data };
            if (this.counter1 == 1) {
              this._app
                .facematch(this.faceCheckId, formData)
                .subscribe((res) => {
                  this._data.changeLoaderVisibility(false);

                  this._data.toastrShow(res.data.data.result, "info");
                  setTimeout(() => {
                    this.closeModal(data);
                  });
                });
            }
          }
        }, 100);
      });
  }
}
