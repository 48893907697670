import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { KycService } from "../../services/kyc.service";
import { Check } from "../../models/check";
import { IDDocument } from "../../models/document";
import { EndUserDocInfo } from "../../models/end-user-doc-info";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";

import { FeasModalComponent } from "../../shared/feas-modal/feas-modal.component";
import { UiStateService } from "../../services/ui-state.service";
import { Ng2DeviceService } from "ng2-device-detector";
@Component({
  selector: "app-address-check",
  templateUrl: "./address-check.component.html",
  styleUrls: ["./address-check.component.scss"],
})

export class AddressCheckComponent implements OnInit {
  
  checkObj: Check;
  isUpdate: boolean = false;
  endUserDocInfo: EndUserDocInfo;

  currentStepNo: any;
  selectedCountry: any;
  countries: any[] = [];
  states: any[] = [];
  validDocument: any = null;
  
  

  idDoc: IDDocument = new IDDocument();

  showSteps: any = {
    showStep1: false,
    showStep2: false,
    showStep3: false,
  };

  selectedId: any = {};
  selectedIdMessage: any = {};

  bsModalRef: BsModalRef;
  extAllowed: any[] = ["image/png", "image/jpeg", "image/jpg" ,"pdf"];
  
 //Mobile Vars
 isMobile: boolean;
 shouldShowInstructions: boolean = false;
 step: number = 1;



  permanentAddress: any = {
    addressLine1: null,
    addressLine2: null,
    landmark :null,
    city : null,
    state : null,
    country : null,
    postalCode : null,
    livingHereFrom :null
  };
  correspondenceAddress: any= {
    addressLine1: null,
    addressLine2: null,
    landmark :null,
    city : null,
    state : null,
    country : null,
    postalCode : null,
    livingHereFrom :null,
   
  };
  isFinal: boolean=false;
  geolocation: any;
  deviceName: any;
  deviceInfo: any;
  ip: any;
  allowDifferentCountryAddressDoc: any;
 

  

  constructor(
    public _app: AppService,
    public _data: DataService,
    public _kyc: KycService,
    private modalService: BsModalService,
    public uiStateService: UiStateService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private deviceService: Ng2DeviceService
  ) {
    console.log("Ever REached Here?");
  }

  ngOnInit() {
    this.currentStepNo = this._kyc.getStepNo("address-doc-check");
    let checks = <Check[]>this._kyc.getData("checks");
    if(checks.length==1){
      this.isFinal=true;
    }
    this.selectedCountry = this._kyc.getData("kycOriginCountry");
    console.log(checks);
    this.checkObj = checks.filter(
      obj => obj.standardCheck.slug == "address-doc-check"
    )[0];
    console.log(this.checkObj);
    console.log(this.currentStepNo);
    this.getUserDocsDetails();
    this.getAllCountries();
    this.getUserAddress();
    this.getAllowedDocs(this.selectedCountry);
    console.log(this.idDoc);
    console.log(this.correspondenceAddress);

    this.isMobile = this.uiStateService.getIsMobileResolution();
    console.log("isMobile: ", this.isMobile);
    this.deviceInformation();
    this.getIp();
    this.getAllDetails();
  }

  getAllCountries() {
    this._app.getAllCountries().subscribe(response => {
      this.countries = response.data;
    });
  }

  getDocFromServer(checkGuid) {
    this._app.getDocFromServer(checkGuid).subscribe(res => {
      this.idDoc[
        "docFrontUrl"
      ] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
      this.idDoc[
        "docRearUrl"
      ] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
    });
  }

  getAllowedDocs(country) {
    console.log(country);
    if (country && this.countries && this.countries.length) {
      let countryCode = this.countries.filter(
        obj => obj.countryName == country
      );
      let countryObj = {
        countryCode: countryCode[0].countryCode,
      };
      this._app.getProvinceList(countryObj).subscribe(res => {
        console.log(res);
        this.states = res.data;
      });
    }
    this._app.getAllowedDocs(country).subscribe(res => {
      let allowedDocs = res.data;
      // if (this.standardCheckGroup == "id-checks") {
      // this.validDocument = allowedDocs["validIdentityDocs"];
      // } else if (this.standardCheckGroup == "address-checks") {
      this.validDocument = allowedDocs["validAddressDocs"];
      // }
      console.log(this.validDocument);
    });
  }
  getAllDetails() {
    this._app.getClientConfig().subscribe(
      response => {
      this.allowDifferentCountryAddressDoc = response.data.allowDifferentCountryAddressDoc
        console.log(this.allowDifferentCountryAddressDoc);
      },
      error => {
        console.log(error);
      }
    );
  }

  getUserDocsDetails() {
    this._app
      .getUserDocsDetails(this.checkObj["endUserCheckID"])
      .subscribe(response => {
        this.isUpdate = true;
        this.endUserDocInfo = response.data.endUserDocInfo;
        this.idDoc = new IDDocument(response.data.endUserDocInfo);
        this.idDoc.docSlug =
          response.data.endUserDocInfo.documentInfo["docSlug"];
        this.idDoc.docType = response.data.endUserDocInfo["docType"];
        if (
          response.data.endUserDocInfo.province &&
          response.data.endUserDocInfo.province["stateCode"]
        ) {
          this.idDoc["provinceCode"] =
            response.data.endUserDocInfo.province["stateCode"];
        }
        this.getAllowedDocs(this.selectedCountry);
        this._kyc.setTraverseState(this.currentStepNo + 1, true);
        // console.log(response);
        // console.log(this.endUserDocInfo);
        // console.log(this._kyc.getData("kycState"));
        this.getDocFromServer(this.checkObj["endUserCheckID"]);
        console.log(this.idDoc);
      });
  }

  selectIdType(doc) {
    // this.idDoc = {} ;

    this.selectedId = doc.docName;
    this.idDoc.docSlug = doc.docSlug;
    console.log(this.selectedId);

    let allowedID = this.validDocument;
    let isAttrAllowed = allowedID.filter(obj => obj.docSlug == doc.docSlug);
    this.selectedIdMessage.rearPhotoMessage = isAttrAllowed[0].rearPhotoMessage;
    this.selectedIdMessage.frontPhotoMessage =
      isAttrAllowed[0].frontPhotoMessage;
    // this.idDoc.docName = doc.docName ;
    console.log(this.selectedId);
    console.log(this.selectedIdMessage);
  }

  fileChangeListener($event, docSlug, docUrlType) {
    var element = <HTMLInputElement>document.getElementById('filename');
    var image: any = new Image();
    var file: File = $event[0] ? $event[0] : $event.target.files[0];
    var name = file.name;
    if (file) {
      if (this.extAllowed.indexOf(file.type) >= 0) {
        var reader = new FileReader();
        var that = this;
        that.openCropModal($event, docSlug, docUrlType);
        element.value =null;
      }
      else if (file.type === "application/pdf") {
        console.log("Selected pdf");
        var fileReader = new FileReader();
        var base64;
        var that = this;
        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
          base64 = fileReader.result;
          // Print data in console
          //   console.log(base64);
          // that.idDoc[docUrlType] = base64;
          that.idDoc[docUrlType + "Pdf"] = { name: file.name, file: base64 };
          element.value =null;
        };
        // Convert data to base64
        fileReader.readAsDataURL(file);
        // this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug ;
      } else {
        let alertObj = {
          message:
            "File extension not supported , we support png , jpeg & jpg.",
          status: "danger",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        // this._data.displayUniversalAlert(alertObj);
        console.log(alertObj);
      }
    }
  }

  onFilesChange($event, docSlug, docUrlType) {
    this.fileChangeListener($event, docSlug, docUrlType);
  }

  openCropModal(event, docSlug, docUrlType) {
    const initialState = {
      title: `Crop Image`,
      type: "crop-image",
      loadedImageEvent: event,
    };
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      // console.log("results", result);
      if (result && Object.keys(result).length) {
        // console.log(result);
        this.idDoc[docUrlType] = result["image"];
        this.idDoc["docSlug"] = docSlug;
      }
    });
  }

  checkAndSave() {
    let countryCode = this.countries.filter(
      obj => obj.countryName == this.selectedCountry
    );
    this.idDoc["countryCode"] = countryCode[0].countryCode;
    console.log("HERE");
    let addressObj = {
      permanentAddress: this.correspondenceAddress,
      correspondenceAddress: this.correspondenceAddress,
    };
    this._app
      .saveUserAddress(addressObj, this._kyc.getData("checkLinkGUID"))
      .subscribe(res => {
        console.log(res);
        let alertObj = {
          message: res["message"],
          status: "success",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        // this._data.displayUniversalAlert(alertObj);
        this._data.toastrShow(res["message"], "info");
        this.saveIdDocs();
        this.getUserAddress();
        console.log(this.correspondenceAddress);
        
        
      });
  }

  getUserAddress() {
    this._app
      .getUserAddress(this._kyc.getData("checkLinkGUID"))
      .subscribe(res => {
        let address = res.data;
        address.livingHereFrom=new Date(address.livingHereFrom)
        console.log("address",address)
        if (address && address.length) {
          this.permanentAddress = address.filter(
            obj => obj.addressType == "PERMANENT"
          )[0]
           
          this.correspondenceAddress = address.filter(
            obj => obj.addressType == "CORRESPONDENCE"
          )[0]
          this.correspondenceAddress.livingHereFrom =new Date(this.correspondenceAddress.livingHereFrom ) ;
        }
        console.log(this.correspondenceAddress);
        this.correspondenceAddress.livingHereFrom =new Date(this.correspondenceAddress.livingHereFrom ) ;
      });
  }

  saveIdDocs() {
    let countryCode = this.countries.filter(
      obj => obj.countryName == this.selectedCountry
    );
    this.idDoc["countryCode"] = countryCode[0].countryCode;
    console.log("Bhai me save Id Docs : ",this.idDoc)
    this.idDoc["docType"] = "ADDRESS";
    
    if(this.idDoc['docFrontUrlPdf']){
			this.idDoc['docFrontUrl'] = this.idDoc['docFrontUrlPdf'].file;
    }
    // End user Actvity log data
    this.idDoc["os"] = this.deviceInfo.os;
    this.idDoc["browser"] = this.deviceInfo.browser;
    this.idDoc["device"] = this.deviceName;
    this.idDoc["location"] = this.geolocation;
    // this.idDoc["ip"] = this.ip;
    this._app
      .saveUserDoc(this.idDoc, this.checkObj["endUserCheckID"])
      .subscribe(res => {
        this.isUpdate = true;
        console.log(res);
        let alertObj = {
          message: res["message"],
          status: "success",
          autoDismiss: true,
          timeToDismiss: 10000,
        };
        this._data.toastrShow(res.message, "info");
        console.log(alertObj);
        this._data.changeLoaderVisibility(false);
        console.log(alertObj);
        this.gotoStep(this.currentStepNo+1)
       
      });
  }

  shouldShowInputField(attr) {
    let selectedDocSlug = this.idDoc["docSlug"];
    let allowedID = this.validDocument;
    let isAttrAllowed = allowedID.filter(obj => obj.docSlug == selectedDocSlug);
    if (isAttrAllowed.length) {
      if (isAttrAllowed[0].mandatoryField.indexOf(attr) > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  shouldShowButton(type) {
    let kycState = <Check[]>this._kyc.getData("kycState");
    let idStateObj = kycState.filter(
      obj => obj["standardSlugGroup"] == "address-doc-check"
    )[0];
    return idStateObj[type];
  }

  finishKyc() {
    console.log("Over");
    
    this.router.navigate(["/kyc/preview"]);
  }

  gotoStep(stepNo) {
    let state = this._kyc.getCurrentState(stepNo);
    let navRoute = this._kyc.getData("routesMap")[state["standardSlugGroup"]];
    console.log(state);
    this.router.navigate([navRoute]);}

    stepForward(currentStep) {
      // this.goingForward = true;
      this.step = currentStep + 1;
      console.log(this.step);
    }
    stepBack(currentStep) {
      // this.goingForward = false;
      this.step = currentStep - 1;
      console.log(this.step);
    }
  

  //show instructions
  showInstructions() {
    this.shouldShowInstructions = this.shouldShowInstructions ? false : true;
  }

  getIp(){
    // this._app.getSystemIp().subscribe((res:any)=>{  
    //   this.ip=res.ip;
    //   console.log("ip adress",this.ip)
    // });
  }
  deviceInformation() {    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      if(this.deviceService.device=="unknown"){
        if(isDesktopDevice){
          this.deviceName='Desktop';
        }
        if(isMobile){
          this.deviceName='Mobile';
        }
        if(isTablet){
          this.deviceName='Tablet';
        }
      }else this.deviceName=this.deviceInfo.device;
    console.log("device info",this.deviceName);
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          this._app.getLocation(position.coords.latitude,position.coords.longitude).subscribe(res=>{
            if(res.city==""){
              this.geolocation=`${res.locality}, ${res.countryName}`;
            }else this.geolocation=`${res.city}, ${res.countryName}`;
                
            console.log("location",this.geolocation)      
          });
          
          // this.geolocation=`lat:${position.coords.latitude},lon:${position.coords.longitude}`;
          // console.log("geolcation",this.geolocation);
          // let geocoder = new google.maps.Geocoder();
        });
    } else {
       this.geolocation=null;
    }
}
}
