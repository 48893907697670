export class State {
	// standardCheckGroup: any;
	standardSlugGroup : any;
	stepNo            : any;
	next              : any;
	previous          : any;
	isFinalStep       : any;
	isAllowed        ?: boolean; //or lets call it isActivated
	constructor() {
		this.standardSlugGroup = null;
		this.stepNo            = null;
		this.next              = null;
		this.previous          = null;
		this.isFinalStep       = null;
		this.isAllowed         = false;
	}
}
