import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { DataService } from "../services/data.service";
import { DataStorageService } from "./data-storage.service";

@Injectable({
  providedIn: 'root'
})
export class KycIndiaService {

  token: any;
  constructor(
    private http: HttpClient,
    private _data: DataService,
    private storage: DataStorageService,
    @Inject("API_URL") public API_URL: any
  ) {
    this.token = this.storage.feas_token;//this._data.getCookiesByName("feas_token");
    console.log(this.token);
  }


  getDetails() {
    return this.http.get<any>(`${this.API_URL}/faas/india-kyc/userchecks`).pipe(
      map(res => {
        return res;
      })
    );
  }

  sendOtp(payload,refPath){
    return this.http.post<any>(`${this.API_URL}/faas/india-kyc/initiate-aadhaar/${refPath}`,payload).pipe(
      map(res => {
        return res;
      })
    );
    
  }

  verifyOtp(payload,refPath){
    return this.http.post<any>(`${this.API_URL}/faas/india-kyc/submit-aadhaar/${refPath}`,payload).pipe(
      map(res => {
        return res;
      })
    );
  }
}
