import { Inject, Injectable } from '@angular/core';
import {  HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DigilockerService {

  constructor(
    private http: HttpClient,
    @Inject("API_URL") public API_URL: any
    ) {

  }

  digiSignIn(id,body) {
    return this.http.post<any>(`${this.API_URL}/faas/india-kyc/digilocker-link/${id}`,body).pipe(
      map(res => {
        return res["data"];
      })
    );
  }

  getDocument(body){
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/digilocker-documents`,body).pipe(
      map(res => {
        return res["data"];
      })
    );
  }

  getDocDetails(body){
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/digilocker-download`,body).pipe(
      map(res => {
        return res["data"];
      })
    );
  }
}

