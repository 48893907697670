import { Component, OnInit } from "@angular/core";
import { DigilockerService } from "../../services/digilocker.service";
import { DataService } from "../../services/data.service";
import { KycIndiaService } from "../../services/kyc-india.service";
import { DataStorageService } from "src/app/services/data-storage.service";

@Component({
  selector: "app-digilock-check",
  templateUrl: "./digilock-check.component.html",
  styleUrls: ["./digilock-check.component.scss"],
})
export class DigilockCheckComponent implements OnInit {
  baseUrl = window.location.origin;
  // reqId=localStorage.getItem("digiReqId");// '43779ca9-03d1-4440-a4fc-02694a34e8af';
  step = 1;
  report: any;
  documentList = [];
  details: any;
  endUserCheckID: any;
  constructor(
    private dls: DigilockerService,
    private data: DataService,
    private kycService: KycIndiaService,
    private storage: DataStorageService
  ) {
    this.kycService.getDetails().subscribe((result) => {
      if (
        result.data["checks"][0]["standardCheck"]["slug"] == "digilocker-check"
      ) {
        this.endUserCheckID = result.data["checks"][0]["endUserCheckID"];
      }
    });
  }

  ngOnInit() {
    // localStorage.removeItem("feasType");
    this.storage.feasType = undefined;
    console.log("location: ", this.baseUrl);
    // if(this.reqId!=undefined && this.reqId!=null){
    //   this.getDocList();
    //   localStorage.removeItem("digiReqId");
    // }
  }

  goToDigilocker() {
    let body = {
      redirectUrl: "https://sandbox.kychub.com/",
    };
    this.data.changeLoaderVisibility(true);
    this.dls.digiSignIn(this.endUserCheckID, body).subscribe((result) => {
      this.data.changeLoaderVisibility(false);
      if (result) {
        // localStorage.setItem("digiReqId",result["data"]["requestId"])
        this.storage.digiReqId = result["data"]["requestId"];
        window.open(result["data"]["result"]["link"]);
      } else {
        this.data.toastrShow("Something went wrong", "error");
      }
    });
  }

  // getDocList(){

  //   console.log("entered doc list: ", this.reqId);
  //   let body={
  //     accessRequestId: this.reqId
  //   }
  //   this.dls.getDocument(body).subscribe(result=>{
  //     this.data.changeLoaderVisibility(false);
  //     if(result){
  //       this.documentList=result;
  //     }
  //     else{
  //       this.data.toastrShow("Something went wrong","error")
  //     }
  //   })
  // }

  // getDocReport(doc){
  //   this.report=doc;
  //   let body={
  //     accessRequestId: this.reqId,
  //     files: [
  //         {
  //             uri: doc.uri,
  //             pdfB64: true,
  //             parsed: true,
  //             xml: true
  //         }
  //     ]
  // }
  //   this.dls.getDocDetails(body).subscribe(result=>{
  //     this.step=3;
  //     console.log("data",this.details);
  //   })

  // }
}
