import { Component, OnInit } from '@angular/core';
import { Jsonp } from '@angular/http';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { Check } from '../../models/check';


@Component({
  selector: 'app-submit-after',
  templateUrl: './submit-after.component.html',
  styleUrls: ['./submit-after.component.scss']
})
export class SubmitAfterComponent implements OnInit {
  checks:Check[]=[];

  constructor(private storage:DataStorageService) {
    this.checks             = <Check[]>JSON.parse(this.storage.remoteChecks);//JSON.parse(localStorage.getItem('remoteChecks'));
   }

  ngOnInit() {
    console.log("checks: ",this.checks);
  }

}
