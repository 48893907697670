import { NgModule } from "@angular/core";
import {
  PreloadAllModules,
  Routes,
  Router,
  RouterModule,
} from "@angular/router";

//Pages
import { TestComponent } from "./pages/test/test.component";
import { Test2Component } from "./pages/test2/test2.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { AccessDeniedComponent } from "./pages/access-denied/access-denied.component";
import { GettingStartedComponent } from "./pages/getting-started/getting-started.component";
import { KycComponent } from "./pages/kyc/kyc.component";
import { AadharFeasComponent } from "./pages/aadhar-feas/aadhar-feas.component";
import { KycIndiaTncComponent } from './pages/kyc-india-tnc/kyc-india-tnc.component'
import { DigilockCheckComponent } from './pages/digilock-check/digilock-check.component';

//Components
import { IdCheckComponent } from "./components/id-check/id-check.component";
import { AddressCheckComponent } from "./components/address-check/address-check.component";
import { SelfieCheckComponent } from "./components/selfie-check/selfie-check.component";
import { PreviewComponent } from "./components/preview/preview.component";

//Services
import { UiStateService } from "./services/ui-state.service";

//Guards
import { AuthGuard } from "./guards/auth.guard";
import { KycGuard } from "./guards/kyc.guard";
import { DeactivateGuard } from "./guards/deactivate.guard";
import { CustomFlowTncComponent } from "./pages/custom-flow-tnc/custom-flow-tnc.component";
import { CustomFlowComponent } from "./pages/custom-flow/custom-flow.component";
import { SubmitAfterComponent } from "./components/submit-after/submit-after.component";

const desktop_routes: Routes = [
  { path: "", redirectTo: "startkyc", pathMatch: "full" },
  { path: "", component: WelcomeComponent, pathMatch: "full" },
  {
    path: "terms",
    component: TermsComponent,
    pathMatch: "full",
    // canActivate: [AuthGuard],
  },
  {
    path: "getting-started",
    component: GettingStartedComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "kyc",
    component: KycComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "id-check", component: IdCheckComponent, data: {idSlug: 'id-doc-check'} },
      { path: "id-check-2", component: IdCheckComponent, data: {idSlug: 'id-doc-check-2'} },
      { path: "address-check", component: AddressCheckComponent },
      { path: "face-check", component: SelfieCheckComponent },
      { path: "preview", component: PreviewComponent },
    ],
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent,
    pathMatch: "full",
  },
  {
    path:'aadhar-verify',
    component: AadharFeasComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'kycindia-tnc',
    component:KycIndiaTncComponent,
    pathMatch:'full',
    canActivate: [AuthGuard]
  },
  {
    path:'digilocker',
    component: DigilockCheckComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'kyc-tnc',
    component:CustomFlowTncComponent,
    pathMatch:'full',
    canActivate: [AuthGuard]
  },
  {
    path:'kyc-verification',
    component:CustomFlowComponent,pathMatch:'full',
    canActivate: [AuthGuard]
  },
  {
    path:'submitAfter',
    component:SubmitAfterComponent,
    pathMatch:'full',
    canActivate: [AuthGuard]
  },
  { path: "test", redirectTo: "test1", pathMatch: "full" },
  { path: "test1", component: TestComponent, pathMatch: "full" },
  { path: "**", redirectTo: "" },
];

const mobile_routes: Routes = [
  { path: "", redirectTo: "startkyc", pathMatch: "full" },
  { path: "", component: WelcomeComponent, pathMatch: "full" },
  {
    path: "terms",
    component: TermsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "getting-started",
    component: GettingStartedComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "kyc",
    component: KycComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "id-check", component: IdCheckComponent, data: {idSlug: 'id-doc-check'} },
      { path: "id-check-2", component: IdCheckComponent, data: {idSlug: 'id-doc-check-2'} },
      { path: "address-check", component: AddressCheckComponent },
      { path: "face-check", component: SelfieCheckComponent },
      { path: "preview", component: PreviewComponent },
    ],
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent,
    pathMatch: "full",
  },
  {
    path:'kycindia-tnc',
    component:KycIndiaTncComponent,
    // pathMatch:'full',
    canActivate: [AuthGuard]
  },
  {
    path:'aadhar-verify',
    component: AadharFeasComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'digilocker',
    component: DigilockCheckComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'kyc-tnc',
    component:CustomFlowTncComponent,
    pathMatch:'full',
    canActivate: [AuthGuard]
  },
  {
    path:'kyc-verification',
    component:CustomFlowComponent,pathMatch:'full',
    canActivate: [AuthGuard]
  },
  {
    path:'submitAfter',
    component:SubmitAfterComponent,
    pathMatch:'full',
    canActivate: [AuthGuard]
  },
  { path: "test", redirectTo: "test2", pathMatch: "full" },
  { path: "test2", component: Test2Component, pathMatch: "full" },
  { path: "**", redirectTo: "" },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(desktop_routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor(
    private router: Router,
    private applicationStateService: UiStateService
  ) {
    if (applicationStateService.getIsMobileResolution()) {
      router.resetConfig(mobile_routes);
    }
  }
}
