import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { reference } from '@angular/core/src/render3';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { KycIndiaService } from '../../../services/kyc-india.service';
@Component({
  selector: 'app-aadhar-number',
  templateUrl: './aadhar-number.component.html',
  styleUrls: ['./aadhar-number.component.scss']
})
export class AadharNumberComponent implements OnInit {
  @Input() state:any;
  @Output() step = new EventEmitter<any>();

  previousData:any;
  form: FormGroup=this.formBuilder.group({
    aadharNo:[''],
    tnc:['']
  })
  errorMsg='';
  referencePath:any;
  referenceId:any;
  customData:any;
  companyName:any;
  logo:any;
  constructor(
    private formBuilder: FormBuilder,
    public _data: DataService,
    private kycService:KycIndiaService
  ) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this._data.customData.subscribe(res=>{
      this.customData=res;
      if(this.customData!=undefined){
    this.customData.data.forEach(element => {
     if(element.keySlug=='company-name'){
        this.companyName=element.keyValue
      }
    });
  }
})
    this.kycService.getDetails().subscribe(result=>{

      if(result.data['checks'][0]['standardCheck']['slug']=='otp-aadhaar-check'){
        this.referencePath=result.data['checks'][0]['endUserCheckID']
      }

      if(this.state!=undefined){
        this.form.patchValue({
          aadharNo:this.state['form']['aadharNo'],
          tnc:this.state['form']['tnc']
        });
      }
    })

    

    console.log("aadhar form; ",this.state);
  }

  tncCheck(value){
    if(value==true){
      this.form.get('tnc').setValue(value);
    }
    else{
      this.form.get('tnc').setValue('');
    }
  }

  sendOtp(){
    this.errorMsg='';
    this._data.changeLoaderVisibility(true);
    let body={
      aadhaar_no:this.form.value['aadharNo']
    }
    this.kycService.sendOtp(body,this.referencePath).subscribe(result=>{
      this._data.changeLoaderVisibility(false);
      if(result.data['code']==200){
        this.referenceId=result.data['reference_id'];
        let state={
          state:{
            form: this.form.value,
            referencePath:this.referencePath,
            refId:this.referenceId
          },
          step:2
        }
        this.step.emit(state)
      }
      else{
        this.errorMsg=result.data['status']['statusMessage'];
      }
    });
  }
}
