interface CountryI {
  countryCode: string;
  countryName: string;
  countrySynonyms: string;
  isActive: string;
  jurisdictions: string;
}

interface IndividualUserInfoI {
  dob: string;
  fatherName: string;
  firstName: string;
  gender: "MALE" | "FEMALE" | null | "";
  individualUserType: string;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  placeOfBirthCity: string;
  placeOfBirthCountry: string;
  placeOfBirthState: string;
  residenceCountry?: CountryI;
  userName?: string;
}

export class IndividualUserInfo implements IndividualUserInfoI {
  dob: any;
  fatherName: string;
  firstName: string;
  gender: "MALE" | "FEMALE" | null | "";
  individualUserType: string;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  placeOfBirthCity: string;
  placeOfBirthCountry: string;
  placeOfBirthState: string;
  residenceCountry?: CountryI;
  userName?: string;

  constructor(individualUserInfo) {
    for (var key in individualUserInfo) {
      this[key] = individualUserInfo[key];
    }
  }
}
