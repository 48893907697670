import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from '../../../../app/services/data.service';

@Component({
  selector: 'app-verification-result',
  templateUrl: './verification-result.component.html',
  styleUrls: ['./verification-result.component.scss']
})
export class VerificationResultComponent implements OnInit {

  @Output() step = new EventEmitter<any>();
  customData:any;
  companyName:any;
  email:any;
  website:any;
  constructor(public _data: DataService) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this._data.customData.subscribe(res=>{
      this.customData=res;
      if(this.customData!=undefined){
    this.customData.data.forEach(element => {
      if(element.keySlug=='company-name'){
        this.companyName=element.keyValue;
      }else if(element.keySlug=='company-support-email'){
        this.email=element.keyValue;
      }else if(element.keySlug=='company-site'){
        this.website=element.keyValue;
      }
    });
  }
})
  }
  openTab(){
    window.open(this.website, '_blank').focus();
  }

}
