import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { FeasModalComponent } from "../../shared/feas-modal/feas-modal.component";
@Component({
  selector: 'app-passive-liviness-new',
  templateUrl: './passive-liviness-new.component.html',
  styleUrls: ['./passive-liviness-new.component.scss']
})
export class PassiveLivinessNewComponent implements OnInit {

  @Input() stepno;
  bsModalRef: BsModalRef;
  @Output() completedStep=new EventEmitter<any>(); 
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  stepComplete(){
    this.completedStep.emit(this.stepno);
  }
  openCameraModal(){
    const initialState = {
      title: `FACE CHECK`,
      type: "face-check",
    };
    this.bsModalRef = this.modalService.show(FeasModalComponent, {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    // console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      this.stepComplete();
    })
  }

}
