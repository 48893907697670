import { Component, OnInit } from '@angular/core';
import { DataService } from "../../services/data.service";
@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

	message : any = "";

	constructor(public _data: DataService) { }

	ngOnInit() {
		// this._data.publicErrInfo.subscribe(val => {
		//       this.message = val;
	 //    });
	}

}
